import { LucideProps } from "lucide-react";



const IconToggleButton = ({ Icon, text, column, onClick, isActive }: { Icon: React.ForwardRefExoticComponent<Omit<LucideProps, "ref"> & React.RefAttributes<SVGSVGElement>>, text: string, column?: boolean, onClick: () => void, isActive: boolean }) => {

    return (
        <div className={isActive ? 'actionButton': 'inactiveActionButton'} style={{ display: "flex", flexDirection: column ? "column": "row", alignItems: "center" }} onClick={onClick}>
            <Icon size={"1.1rem"} />
            <div style={{ marginLeft: "0.21875rem", fontSize: "0.79rem" }}>{text}</div>
        </div>
    );
}


export default IconToggleButton;