
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import General from '../components/TopTab/General';
import MapTab from '../components/TopTab/Map';
import Print from '../components/TopTab/Print';
import Rest from '../components/TopTab/Rest';
import { Department, Terminal, Trailer, Vehicle } from '../models/Department';
import { LocationData } from '../models/LocationData';
import { getActiveUsers, getConversations, getDeliveredOrders, getDistributedOrders, getLoadedOrders, getPassiveData, getUnDistributedOrders } from '../Services/lookupService';
import { actionCreators } from '../state';
import { RootState, store } from '../state/store';
  
import ConversationList from '../components/ConversationList';
import MessageModal, { ImessgeModalProps } from '../components/messageModal';
import MissionInfoWindow from '../components/MissionInfoWindow';
import { ModalProps } from '../components/Modal';
import StatusMenue from '../components/StatusMenue';
import ComingDeliveries from '../components/tableTabs/ComingDeliveries/comingDeliveries';
import ComingPickups from '../components/tableTabs/ComingPickups/comingPickups-direct';
import Delivered from '../components/tableTabs/Delivered/delivered';
import Deliveries from '../components/tableTabs/Deliveries/deliveries';
import PickupsTables from '../components/tableTabs/pickups/pickups-direct';
import AllStatus from '../components/tableTabs/Status/AllStatus';
import TimeLimit from '../components/tableTabs/TimeLimit/TimeLimit';
import DisplayTab from '../components/TopTab/Display';
import MapComponent from '../components/VehicleMap';
import WindowCard from '../components/Window';
import StorageKeys from '../helpers/StorageKeys';
import snackbar from '../helpers/toastrHelper';
import { customerTypeMap as customerColorMap } from '../helpers/TranslationMaps';
import { AuthResult } from '../models/AuthResult';
import { CurrentOrderData } from '../models/CurrentOrderData';
import { Conversation, Message, MessageUpload } from '../models/messages';
import { User } from '../models/UserResult';
import { defaultViewSettings, ViewSettings } from '../models/ViewSettings';
import { dispatchOrder, newMessage } from '../Services/operationService';
import { initSocketStream } from '../Services/Socket';
import '../styles/styles.css';

delete (L.Icon.Default.prototype as any)._getIconUrl;


interface HidableButton {
    id: string;
    name: string;
    hidden: boolean;
    active?: boolean;
    blocking: boolean;
    hoverContent?: () => JSX.Element;
}

const GeneralPage = () => {

    const topMenuItems: string[] = ["Allmänt", "Utskrift", "Karta", "Övrigt", "Visa"];
    const resourceMenuItems: string[] = ["Uppdrag", "Resursinformation"];
    const midMenuItems: string[] = ["Fordon", "Trailrar", "Terminal"];

    const tableTopMenuItems: string[] = ["Uppdrag"/*, "Schemaplanering", "Resa"*/];
    const tableBottomMenuItems: string[] = ["Hämtningar / direktkörningar", "Utkörningar", "Kommande Hämtningar / direktkörningar", "Kommande Utkörningar", "Lossade", "Alla Statusar", "Tidspassning"];

    const [sideMenuItems, setSideMenuItems] = useState<HidableButton[]>([
        // { id: 'suppliers', name: "underLeverantörer", hidden: false, blocking: true },
        { id: 'tables', name: "Tables", hidden: false, blocking: true, hoverContent: undefined /* () => <ColumnFilters columns={columns} setColumnFilters={setColumns} />*/ },
        // { id: 'changes', name: "ändringar", hidden: false, blocking: true },
        { id: 'messages', name: "Meddelanden", hidden: false, active: true, blocking: false },
        { id: 'routes', name: "Rutter", hidden: false, blocking: true },
        // { id: 'schedule', name: "Kö", hidden: false, blocking: true },
        // { id: 'link', name: "k2 Link", hidden: false, blocking: true },
        { id: 'map', name: "Karta", hidden: false, active: true, blocking: false },
        // { id: 'suggest', name: "Resursförslag", hidden: false, blocking: true },
    ]);

    const activeData = useSelector((state: RootState) => state.activeData);
    const auth = useSelector((state: RootState) => state.auth);

    const addPassiveData = bindActionCreators(actionCreators, store.dispatch).addPassiveData;
    const addActiveData = bindActionCreators(actionCreators, store.dispatch).addActiveData;
    const addAuth = bindActionCreators(actionCreators, store.dispatch).addAuth;

    const [departments, setDepartments] = useState<Department[]>([]);
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [trailers, setTrailers] = useState<Trailer[]>([]);
    const [terminals, setTerminals] = useState<Terminal[]>([]);

    const [userPositions, setUserPositions] = useState<LocationData[]>([]);
    const [conversations, setConversations] = useState<Conversation[] | undefined>(undefined);
    const [activeOrders, setActiveOrders] = useState<CurrentOrderData | undefined>(undefined);

    const [viewSettings, setViewSettings] = useState<ViewSettings>(defaultViewSettings);

    const [selectedDepartment, setSelectedDepartment] = useState<Department | undefined>(undefined);
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>(undefined);
    const [selectedTrailer, setSelectedTrailer] = useState<Trailer | undefined>(undefined);
    const [selectedTerminal, setSelectedTerminal] = useState<Terminal | undefined>(undefined);

    const [selectedMission, setSelectedMission] = useState<Record<string, any> | undefined>(undefined);

    const [popoutmenue, setPopoutmenue] = useState<JSX.Element | undefined>(undefined);

    const [modal, setModal] = useState<ModalProps | undefined>(undefined);
    const [messageModal, setMessageModal] = useState<ImessgeModalProps | undefined>(undefined);

    const [activeKeys, setActiveKeys] = useState<Set<string>>(new Set<string>());

    const [clicks, setClicks] = useState<number>(0);

    const timer = useRef<NodeJS.Timeout | null>(null);

    const hasFetchedData = useRef<boolean>(false);
    const keLogEnabled = useRef<boolean>(true);
    const selectedMissionRef = useRef<Record<string, any> | undefined>(selectedMission);
    const selectedVehicleRef = useRef<Vehicle | undefined>(selectedVehicle);
    const modalRef = useRef<ModalProps | undefined>(modal);
    const messageModalRef = useRef<ImessgeModalProps | undefined>(messageModal);
    const centeredUserRef = useRef<L.LatLngTuple | undefined>(undefined);

    const [selectedTopTab, setSelectedTopTab] = useState<number>(0);
    const [selectedResourceTab, setSelectedResourceTab] = useState<number>(0);
    const [selectedMidTab, setSelectedMidTab] = useState<number>(0);
    const [selectedTableTopTab, setSelectedTableTopTab] = useState<number>(0);
    const [selectedTableBottomTab, setSelectedTableBottomTab] = useState<number>(0);


    const _getPassiveData = async () => {
        try {
            const result = await getPassiveData();

            console.log("passiveData", result.departments.length, result.externalTerminals.length, result.vehicles.length, result.users.length, result.codes.length);

            if (result) {

                const data = { departments: result.departments, vehicles: result.vehicles, externalTerminals: result.externalTerminals, users: result.users, appcodes: result.codes };

                addPassiveData(data);

                localStorage.setItem(StorageKeys.passiveData, JSON.stringify(data));

                const dep = result.departments.find((dep) => dep.departmentCode === auth?.auth.login.department);

                setDepartments(result.departments);
                setSelectedDepartment(dep);
                setTerminals(result.departments.map<Terminal>((dep) => ({ name: dep.departmentName, code: dep.departmentCode })).concat(result.externalTerminals));

                setVehicles(result.vehicles);
                setTrailers(result.trailers);
                
                setUsers(result.users);

                for (let i = 0; i < result.customers.length; i++) {
                    let color = result.customers[i].backgroundColor;

                    if (color != null) {
                        let rgbHex = ((color >>> 0) & 0xffffff).toString(16);

                        rgbHex = "#" + rgbHex.padStart(6, '0');

                        customerColorMap[result.customers[i].code] = rgbHex;
                    }
                }

                console.log("customerTypeMap", customerColorMap);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const _getLocations = async () => {
        try {
            const result = await getActiveUsers();

            console.log("users", result.length);
            
            if (result) {
                setUserPositions(result);

                addActiveData({ usePositions: result });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const _getunDistributed = async () => {
        const start = DateTime.now();
        try {
          const result = await getUnDistributedOrders();
    
          console.log("unDistributed orders", result.length, `(${start.diffNow().milliseconds * -1}ms)`);
          
          if (result) {
            setActiveOrders((prev) => ({ ...prev, unDistributed: result }));
          }
        } catch (error) {
          console.log(error);
        }
    };
    
    const _getDistributed = async () => {
        const start = DateTime.now();
        try {
          const result = await getDistributedOrders();
    
          console.log("Distributed orders", result.length, `(${start.diffNow().milliseconds * -1}ms)`);
          
          if (result) {
            setActiveOrders((prev) => ({ ...prev, distributed: result }));
          }
        } catch (error) {
          console.log(error);
        }
    };
    
    const _getLoaded = async () => {
        const start = DateTime.now();
        try {
          const result = await getLoadedOrders();
    
          console.log("Loaded orders", result.length, `(${start.diffNow().milliseconds * -1}ms)`);
          
          if (result) {
            setActiveOrders((prev) => ({ ...prev, loaded: result }));
          }
        } catch (error) {
          console.log(error);
        }
    };
    
    const _getDelivered = async () => {
        const start = DateTime.now();
        try {
          const result = await getDeliveredOrders();
    
          console.log("Delivered orders", result.length, `(${start.diffNow().milliseconds * -1}ms)`);
          
          if (result) {
            setActiveOrders((prev) => ({
              ...prev,
              department: selectedDepartment?.departmentCode,
              delivered: result,
            }));
          }
        } catch (error) {
          console.log(error);
        }
    };

    const _getConversations = async () => {
        const start = DateTime.now();
        try {
            const result = await getConversations();

            console.log("Conversations", result.conversations.length, `(${start.diffNow().milliseconds * -1}ms)`);
            
            if (result) {
                setConversations(result.conversations);
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(() => {
        if (hasFetchedData.current) return;
        hasFetchedData.current = true;
      
        const fetchData = async () => {
          try {
            await _getPassiveData();
            await _getLocations();
            await _getConversations();
            await _getActiveOrders();
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
      
        fetchData();
    }, []);

    const _getActiveOrders = async () => {
        await Promise.all([
          _getunDistributed(),
          _getDistributed(),
          _getLoaded(),
          _getDelivered(),
        ]);
    };

    useEffect(() => {
        setUserPositions(activeData.usePositions);
    }, [activeData]);

    useEffect(() => {
        selectedMissionRef.current = selectedMission;
    }, [selectedMission]);

    useEffect(() => {
        selectedVehicleRef.current = selectedVehicle;
    }, [selectedVehicle]);
    
    useEffect(() => {
        modalRef.current = modal;
        messageModalRef.current = messageModal;
    }, [modal, messageModal]);

    useEffect(() => {
        if (activeOrders !== undefined) {
            const newAuth: AuthResult = { ...auth!.auth, login: { ...auth!.auth.login, department: selectedDepartment?.departmentCode } };

            addAuth({ auth: newAuth });

            setSelectedVehicle(undefined);
            centeredUserRef.current = undefined;

            setSelectedMission(undefined);
            setActiveOrders(undefined);

            setTimeout(_getActiveOrders, 125);

            localStorage.setItem(StorageKeys.user, JSON.stringify(newAuth));
        }
    }, [selectedDepartment]);

    useEffect(() => {
        if (auth?.auth.refreshToken && selectedDepartment !== undefined && vehicles.length > 0) {
          initSocketStream(auth?.auth.accessToken, selectedDepartment, setActiveOrders);
        }
    }, [auth?.auth.refreshToken, selectedDepartment, vehicles, setActiveOrders]);

    useEffect(() => {
        if (selectedMission !== undefined) {
            setSelectedMission(undefined);
        }
    }, [selectedTableBottomTab]);


    useEffect(() => {
        const handleKeyDown = (ev: KeyboardEvent) => {
            if (!keLogEnabled.current && ev.key !== "Escape") return;

            setActiveKeys((prevKeys) => {
                if (prevKeys.has(ev.key)) {
                    return prevKeys;
                }
    
                const newKeys = new Set<string>(prevKeys);
                newKeys.add(ev.key);
    
                console.log(`Key pressed: ${ev.key}`, Array.from(newKeys));

                if (newKeys.has("Escape") && (modalRef.current !== undefined || messageModalRef.current !== undefined)) {
                    if (modalRef.current) {
                        modalRef.current.onClose();
                    } else if (messageModalRef.current) {
                        messageModalRef.current.onClose();
                    }
                    
                    return new Set<string>();
                } else if (newKeys.has(" ") || (newKeys.has('Control') && newKeys.has('o'))) {
                    ev.preventDefault();
                    openNewTab();

                    return new Set<string>();
                } else if (newKeys.has('Control') && newKeys.has('Alt')) {
                    if (selectedVehicleRef.current !== undefined) {

                        const convo = conversations?.find((convo) => convo.members.length == 2 && convo.members.map((user) => user.username).includes(selectedVehicleRef.current!.vehicleName));

                        if (convo) {
                            showMessageModal(selectedVehicleRef.current.vehicleName, convo.conversationId, convo.global);
                        } else {
                            snackbar.error("Hittade ingen konversation!", "Ingen konversation");
                        }

                    } else {
                        snackbar.error("Inget fordon valt!", "Inget fordon");
                    }

                    return new Set<string>();
                }
    
                return newKeys;
            });
        };
    
        const handleKeyUp = (ev: KeyboardEvent) => {
            if (!keLogEnabled.current && ev.key !== "Escape") return;

            setActiveKeys((prevKeys) => {
                if (!prevKeys.has(ev.key)) {
                    return prevKeys;
                }
    
                const newKeys = new Set(prevKeys);
                newKeys.delete(ev.key);
    
                console.log(`Key released: ${ev.key}`, Array.from(newKeys));
    
                return newKeys;
            });
        };
    
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
    
        console.log('Key listeners added');
    
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            console.log('Key listeners removed');
        };
    }, []);


    const showMessageModal = (recipent: string, id: number, global: boolean = false) => {
        keLogEnabled.current = false;

        console.log("showing modal");

        setMessageModal({
            title: `Nytt medelande till ${recipent}${global ? " (global)": ""}`,
            onClose: () => { 
                keLogEnabled.current = true;
                setMessageModal(undefined);
            },
            onconfirm: (result: string | undefined) => {
                if (result) {
                    sendMessage(id, result);
                }

                setMessageModal(undefined);
            },
        });
    }


    const moveItem = async (item: any) => {
        if (selectedVehicle === undefined) {
            snackbar.error("Inget fordon valt!", "Inget fordon");
            return;
        }

        try {
            console.log("Dispatching order", item.orderNr, "to", selectedVehicle.vehicleName);

            const result = await dispatchOrder(item.orderNr, selectedVehicle.vehicleName);

            console.log("dispatch result", result);
        } catch (e) {
            console.log("Error dispatching order", e);
        }
    }

    const selectItem = (item: any) => {
        setClicks((prev) => prev + 1);
        const currentClicks = clicks + 1;

        if (currentClicks === 1) {
            timer.current = setTimeout(() => {
            
                console.log("Item Single tapped", item?.orderNr);
                if (selectedMission?.orderNr == item?.orderNr) {
                    setSelectedMission(undefined);
                } else {
                    setSelectedMission(item);
                }

                setClicks(0);
            }, 200);
        } else if (currentClicks >= 2) {
            if (timer.current) {
              clearTimeout(timer.current);
            }

            moveItem(item);
            setClicks(0);
        }
    }

    const selectStatusItem = (item: any) => {
        console.log("Status Item Single tapped", item);

        // if (selectedMission?.orderNr == item?.orderNr) {
        //     setSelectedMission(undefined);
        // } else {
        //     setSelectedMission(item);
        // }
    }

    const openNewTab = () => {
        const mission = selectedMissionRef.current;
        if (!mission) { 
            console.log("No order selected", mission);
            return;
        }
    
        const url = `/Order?data=${encodeURIComponent(mission.orderNr)}`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const messageTap = (item: Conversation) => {
        setClicks((prev) => prev + 1);
        const currentClicks = clicks + 1;

        if (currentClicks === 1) {
            timer.current = setTimeout(() => {

                showMessageModal(item.groupName, item.conversationId, item.global);

                setClicks(0);
            }, 200);
        } else if (currentClicks >= 2) {
            if (timer.current) {
              clearTimeout(timer.current);
            }

            showMessageModal(item.groupName, item.conversationId, item.global);
            setClicks(0);
        }
    }

    const sendMessage = async (convoId: number, message: string) => {
        const msg: Message = {
            conversationId: convoId,
            uid: auth?.auth.login.uid || 0,
            refId: undefined,
            images: [],
            text: message.trim() != "" ? message : undefined,
            timeStamp: DateTime.now().toString(),
            edited: false,
            id: 0, 
        };

        const newMsg: MessageUpload = {
            conversationId: convoId,
            text: message.trim() != "" ? message : null,
            refId: undefined,
            images: [],
        };

        try {
            const result = await newMessage(newMsg);

            console.log("message result", result);

            if (!result.success) return;

            msg.id = result.id;
        } catch (e) {
            console.log("could not send message");
        }

        setConversations((prev) => {
            if (!prev) return prev;

            const convoindex = prev.findIndex((convo) => convo.conversationId == convoId);

            prev[convoindex].messages = prev[convoindex].messages.concat(msg);

            return prev;
        });

    }

    const topTabs = topMenuItems.map((value, index) => (
        <div
            key={index}
            className={`Tab ${index === selectedTopTab ? 'selected' : 'notSelected'}`}
        >
            <div onClick={() => setSelectedTopTab(index)}>{value}</div>
        </div>
    ));

    const resourceTabs = resourceMenuItems.map((value, index) => (
        <div
            key={index}
            className={`invTab ${index === selectedResourceTab ? 'invSelected' : 'invNotSelected'}`}
        >
            <div onClick={() => setSelectedResourceTab(index)}>{value}</div>
        </div>
    ));

    const midTabs = midMenuItems.map((value, index) => (
        <div
            key={index}
            className={`invTab ${index === selectedMidTab ? 'invSelected' : 'invNotSelected'}`}
        >
            <div onClick={() => setSelectedMidTab(index)}>{value}</div>
        </div>
    ));

    const tableTopTabs = tableTopMenuItems.map((value, index) => (
        <div
            key={index}
            className={`Tab ${index === selectedTableTopTab ? 'selected' : 'notSelected'}`}
            style={{ padding: "0.125rem 0.25rem 0.15625rem 0.25rem" }}
        >
            <div onClick={() => setSelectedTableTopTab(index)}>{value}</div>
        </div>
    ));


    const tableBottomTabs = tableBottomMenuItems.map((value, index) => (
        <div
            key={index}
            className={`Tab ${index === selectedTableBottomTab ? 'selected' : 'notSelected'}`}
            style={{ padding: "0.125rem 0.25rem 0.15625rem 0.25rem", flexWrap: "nowrap" }}
        >
            <div onClick={() => setSelectedTableBottomTab(index)}>{value}</div>
        </div>
    ));

    const topTabsWithInfo = (
        <>
            {topTabs}
            <div className='infoBox'>Total active users {userPositions.length}</div>
        </>
    );

    const topMenue = () => {

        switch (selectedTopTab) {
            case 0:
                return <General settings={viewSettings} updateSettings={setViewSettings} selectedItem={selectedMission} departments={departments} selectedDepartment={selectedDepartment} onDepartmentChange={(department) => setSelectedDepartment(department)}></General>
            case 1:
                return <Print settings={viewSettings} updateSettings={setViewSettings}></Print>
            case 2:
                return <MapTab settings={viewSettings} updateSettings={setViewSettings}></MapTab>
            case 3:
                return <Rest settings={viewSettings} updateSettings={setViewSettings}></Rest>
            case 4:
                return <DisplayTab settings={viewSettings} updateSettings={setViewSettings}></DisplayTab>
        }
    };


    const bottomTables = (): JSX.Element => {
        switch (selectedTableBottomTab) {
            case 0:
                return <PickupsTables 
                    selectedItem={selectedMission?.orderNr} 
                    filters={viewSettings.columnFilters}
                    onTap={selectItem} 
                    createdNormal={activeOrders?.unDistributed?.filter((order) => order.delivery == "0")} 
                    createdDirect={[]} 
                    distributed={activeOrders?.distributed?.filter((order) => order.delivery == "0" && (selectedVehicle === undefined || selectedVehicle.vehicleName.replaceAll(/[^a-zåäö0-9]+/igm, "") === order.actualVehicle))} 
                    loaded={activeOrders?.loaded?.filter((order) => order.delivery == "0" && (selectedVehicle === undefined || selectedVehicle.vehicleName.replaceAll(/[^a-zåäö0-9]+/igm, "") === order.actualVehicle))} 
                />
            case 1:
                return <Deliveries 
                    selectedItem={selectedMission?.orderNr} 
                    filters={viewSettings.columnFilters}
                    onSingleTap={selectItem} 
                    onDoubleTap={moveItem} 
                    created={activeOrders?.unDistributed?.filter((order) => order.delivery == "1")} 
                    distributed={activeOrders?.distributed?.filter((order) => order.delivery == "1" && (selectedVehicle === undefined || selectedVehicle.vehicleName.replaceAll(/[^a-zåäö0-9]+/igm, "") === order.actualVehicle))} 
                    loaded={activeOrders?.loaded?.filter((order) => order.delivery == "1" && (selectedVehicle === undefined || selectedVehicle.vehicleName.replaceAll(/[^a-zåäö0-9]+/igm, "") === order.actualVehicle))}
                />
            case 2:
                return <ComingPickups 
                    filters={viewSettings.columnFilters}
                    normal={[]} 
                    direct={[]} 
                    selectedItem={selectedMission?.orderNr}                     
                    onSingleTap={selectItem} 
                    onDoubleTap={moveItem} 
                />
            case 3:
                return <ComingDeliveries 
                    created={[]}
                    filters={viewSettings.columnFilters}
                    selectedItem={selectedMission?.orderNr}                     
                    onSingleTap={selectItem} 
                    onDoubleTap={moveItem} 
                />
            case 4:
                return <Delivered 
                    items={activeOrders?.delivered}
                    filters={viewSettings.columnFilters}
                    selectedItem={selectedMission?.orderNr} 
                    onSingleTap={selectItem}
                />
            case 5:
                return <AllStatus 
                    items={activeOrders?.unDistributed?.concat(activeOrders?.distributed || []).concat(activeOrders?.loaded || []).concat(activeOrders?.delivered?.filter((item: any) => (item.extraid5 as string | undefined)?.toLowerCase().includes("tidspass")) || [])}
                    filters={viewSettings.columnFilters}
                    selectedItem={selectedMission?.orderNr}
                    // onRightClick={selectStatusItem}
                />
            case 6:
                return <TimeLimit 
                    filters={viewSettings.columnFilters}
                    selectedItem={selectedMission?.orderNr}
                    items={activeOrders?.loaded?.filter((item: any) => (item.extraid5 as string | undefined)?.toLowerCase().includes("tidspass")).concat(activeOrders?.delivered?.filter((item: any) => (item.extraid5 as string | undefined)?.toLowerCase().includes("tidspass")) || [])}
                    onSingleTap={selectItem}
                />
        }

        return <div>not mapped</div>;
    };

    const resourceMenu = (
        <div style={{ marginLeft: "0.375rem" }}>
            <WindowCard title={selectedResourceTab == 0 ? "Uppdrag" : "Resursinformation"} disableClose>
                {selectedResourceTab == 0 ? (selectedMission && <MissionInfoWindow mission={selectedMission}></MissionInfoWindow>): undefined}
            </WindowCard>
            <div style={{ marginLeft: "0.3125rem" }}>
                {resourceTabs}
            </div>
        </div>
    );

    const vehicleMenue = (
        <div>
            <div style={{ fontSize: "0.9rem", margin: 2, marginBottom: 1 }}>{midMenuItems[selectedMidTab] + (selectedMidTab == 0 ? `, ${userPositions.filter((user) => selectedDepartment === undefined || user.department == selectedDepartment?.departmentCode).length} aktiva`: "")}</div>
            <div style={{ marginLeft: "0.3125rem" }}>
                <div className='vehicleSection'>
                    {selectedMidTab === 0 && vehicles.filter((vehicle) => selectedDepartment === undefined || (vehicle.department == selectedDepartment?.departmentCode && (!viewSettings.hideinactiveUsers || moment().diff(moment(userPositions.find((user) => user.vehicle == vehicle.vehicleName)?.timestamp || "2000-01-01 01:01:01"), 'minutes') < 20))).map((vehicle, index, items) => {

                        const user = userPositions.find((user) => user.vehicle == vehicle.vehicleName);

                        return (
                            <div id={vehicle.id} className={`vehicleItem${(selectedVehicle?.id == vehicle.id ? " selectedMidItem": "")}${user ? (moment().diff(moment(user.timestamp), 'minutes') > 30 ? " recentlyActive" : " active"): ""}`} style={ items.length <= 8 ? { maxWidth: "7.75rem" }: undefined} onClick={() => {
                                if (selectedVehicle?.id != vehicle.id) {
                                    setSelectedVehicle(vehicle); 
                                    if (activeKeys.has("Control")) {
                                        centeredUserRef.current = [user?.latitude || 0, user?.longitude || 0];
                                    }
                                } else {
                                    setSelectedVehicle(undefined);
                                    if (centeredUserRef.current !== undefined) {
                                        centeredUserRef.current = undefined;
                                    }
                                }
                            }}>
                                <div className='vehicleName'>{vehicle.vehicleName}</div>
                                <div className='vehicleUsername'>{users.find((user) => user.vehicle == vehicle.vehicleName)?.username}</div>
                            </div>
                        ); 
                    })}
                    {selectedMidTab === 1 && trailers.filter((trailer) => selectedDepartment === undefined || trailer.department == selectedDepartment?.departmentCode).map((trailer) => (
                        <div id={trailer.id} className={`vehicleItem ${(selectedTrailer?.id == trailer.id ? "selectedMidItem": "")}`} onClick={() => {
                            if (selectedTrailer?.id != trailer.id) {
                                setSelectedTrailer(trailer); 
                            } else {
                                setSelectedTrailer(undefined);
                            }
                        }}>
                            {trailer.trailerPlate}
                        </div>
                    ))}
                    {selectedMidTab === 2 && terminals.map((terminal) => (
                        <div id={"" + terminal.code} className={`vehicleItem active ${(selectedTerminal?.code == terminal.code ? "selectedMidItem": "")}`} onClick={() => {
                            if (selectedTerminal?.code != terminal.code) {
                                setSelectedTerminal(terminal); 
                            } else {
                                setSelectedTerminal(undefined);
                            }
                        }}>
                            {terminal.code}
                        </div>
                    ))}
                </div>
            </div>
            <div style={{ marginLeft: 10 }}>
                {midTabs}
            </div>
        </div>
    );


    const BottomSection = (
        <div className='bottomSection'>
            {/* <div style={{ marginLeft: 5 }}>
                {tableTopTabs}
            </div> */}
            <div>
                {tableBottomTabs}
            </div>
            <div className="bottomTablesContainer">
                {bottomTables()}
            </div>
        </div>
    );

    const ui = (
        <>
            {messageModal && <MessageModal {...messageModal} />}
            <div className='mainContainer'>
                <div className='spacedRow'>
                    <div style={{ marginLeft: "0.25rem" }}>
                        <div>{topTabsWithInfo}</div>
                        <div>{topMenue()}</div>
                    </div>
                    <StatusMenue activeOrders={activeOrders} />
                </div>
                <div className='row'>
                    <div className='wmax'>
                        <div>{resourceMenu}</div>
                        <div>{vehicleMenue}</div>
                        <div>{BottomSection}</div>
                    </div>
                    <div className='column'>
                        {sideMenuItems.find((item) => item.id == "messages")?.active && <ConversationList auth={auth} users={users} conversations={conversations} onSingleTap={messageTap} />}
                        {sideMenuItems.find((item) => item.id == "map")?.active      && <MapComponent data={activeData} users={users} department={selectedDepartment} departments={departments} centered={centeredUserRef.current} />}
                    </div>
                    {popoutmenue}
                    <div className='verticalSection'>
                        {sideMenuItems.filter((item: HidableButton) => !item.hidden).map((value) => (
                            <div
                                key={value.id}
                                onClick={() => setSideMenuItems((prev) => prev.map((item) => item.id === value.id ? { ...item, active: !item.active }: item))}
                                onMouseOver={value.hoverContent !== undefined ? () => setPopoutmenue((prev) => prev === undefined ? value.hoverContent!(): undefined): undefined}
                                className={value.active ? 'verticalButton verticalButtonActive' : 'verticalButton'}
                            >
                                {value.name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );

    return ui;
};

export default GeneralPage;
