import React from "react";
import { Conversation, Message } from "../models/messages";
import { User } from "../models/UserResult";
import { formatTimestamp, sortConversations } from "../Services/format";
import { AuthState } from "../state/reducers/authReducer";

import {
  People20Filled
} from "@fluentui/react-icons";
import "../styles/messages.css";
import { DotLoadingIndicator } from "./loadingIndicator";

export interface ConversationListProps {
  users: User[];
  auth: AuthState | null;
  conversations: Conversation[] | undefined;
  onSingleTap: (item: Conversation) => void;
}

const ConversationList = (props: ConversationListProps) => {

  if (props.conversations === undefined) {
    return <DotLoadingIndicator />;
  }

  function formatPreview(conversation: Conversation, currentUid: number, users: User[]): string {
    if (conversation.messages.length === 0) return "";
  
    const lastMessage: Message = conversation.messages[conversation.messages.length - 1];
  
    if (lastMessage.uid == -1) {
      return lastMessage.text?.trim() ?? "";
    }
  
    let senderName: string;
  
    if (lastMessage.uid == currentUid) {
      senderName = "You";
    } else if (conversation.global) {
      const user = users.find((user) => user.id == lastMessage.uid);
      senderName = user?.username ?? "Okänd";
    } else {
      const member = conversation.members.find((member) => member.uid == lastMessage.uid);
      senderName = member?.username ?? "Okänd";
    }
  
    const cameraEmojis: string = "📸".repeat(lastMessage.images.length);
  
    const messageText: string = lastMessage.text?.trim() ?? "";
  
    return `${senderName}: ${cameraEmojis}${messageText}`;
  }
  
  return (

    <div className="messageView">
      <div></div>
      <div className="messageList">
        {props.conversations && props.conversations.length > 0 ? (
          <ul className="List">
            {sortConversations(props.conversations).map((item) => (
              <li
                key={item.conversationId}
                className="conversationCard"
                onClick={() =>props.onSingleTap(item)}
              >
                <div className="titleCol">
                  <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', flex: 1 }}>
                    <span  className="title">{item.groupName}</span>
                    {item.messages.length > 0 && (
                      <span className="timestamp">
                        {formatTimestamp(item.messages[item.messages.length - 1].timeStamp)}
                      </span>
                    )}
                  </div>
                  <div style={{ display: 'flex', flexDirection: "row" }}>
                    <span className="previewText">
                      {formatPreview(item, props.auth?.auth.login.uid || -1, props.users)}
                    </span>
                    <span style={{ fontSize: '0.75rem', color: 'black', fontWeight: 'bold', marginRight: '0.25rem' }}>
                      {!item.global ? item.members.length : '(global)'}
                    </span>
                    {!item.global && <People20Filled color="#3B82F6" />}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="noConversations">
            <p className="noConversationsText">
              {'inget att se här,\nStarta en konversation!'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
  
export default React.memo(ConversationList);