import { ArrowDown20Filled, ArrowUp20Filled, BinRecycleFull20Regular, Document20Regular, VehicleCarProfileRtl20Filled } from "@fluentui/react-icons";


interface IMissionInfoProps {
    deliveryInfo: Record<string, any>[];
}

const MissionAddressInfo = (props: IMissionInfoProps) => {

    return (
        <div className="orderSection" style={{ flexDirection: "column" }}>
                <h3>Adresser</h3>
                <div>
                    <div className="row">
                        <button className="iconButton">
                            <div className="align">
                                <Document20Regular />
                                Ny sist
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <Document20Regular />
                                Ny före
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <Document20Regular />
                                Ny efter
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <BinRecycleFull20Regular />
                                Ta Bort
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                            <ArrowUp20Filled />
                                Flytta upp
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <ArrowDown20Filled />
                                Flytta ner
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <VehicleCarProfileRtl20Filled />
                                Sätt resurs på alla uppdrag
                            </div>
                        </button>
                    </div>
                </div>
                <div style={{ display: "flex", flex: 1, flexDirection: "column", flexGrow: 1 }}>
                    {props.deliveryInfo.length > 0 ? 
                        props.deliveryInfo.map((step: any, index: number) => {



                            return (
                                <div className=""> text</div>
                            ); 
                        }): 
                        (
                        <div> text</div>
                        )
                    }
                </div>
            </div>
    )
}

export default MissionAddressInfo;