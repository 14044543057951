

import { Settings } from "lucide-react";
import { ViewSettings } from "../../models/ViewSettings";
import IconToggleButton from "../IconToggleButton";


interface DisplayTabProps {
    settings: ViewSettings;
    updateSettings: React.Dispatch<React.SetStateAction<ViewSettings>>;
}

const DisplayTab = (props: DisplayTabProps) => {



    return (
        <div className='topmenue'>
            <div className='largeSection'>
                <div className='spacedRow m-1'>
                    <div className='column'>
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Uppdragsinformation"
                            
                            onClick={() => props.updateSettings({...props.settings, displayMissionInfo: !props.settings.displayMissionInfo})} 
                            isActive={props.settings.displayMissionInfo} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Resursinformation"
                            
                            onClick={() => props.updateSettings({...props.settings, displayUserInfo: !props.settings.displayUserInfo})} 
                            isActive={props.settings.displayUserInfo} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Fordon"
                            
                            onClick={() => props.updateSettings({...props.settings, displayVehicles: !props.settings.displayVehicles})} 
                            isActive={props.settings.displayVehicles} 
                        />
                    </div>

                    <div className='column'>
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Förare"
                            
                            onClick={() => props.updateSettings({...props.settings, displayDrivers: !props.settings.displayDrivers})} 
                            isActive={props.settings.displayDrivers} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Trailrar"
                            
                            onClick={() => props.updateSettings({...props.settings, displayTrailers: !props.settings.displayTrailers})} 
                            isActive={props.settings.displayTrailers} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Underleverantörer"
                            
                            onClick={() => props.updateSettings({...props.settings, displayDelegates: !props.settings.displayDelegates})} 
                            isActive={props.settings.displayDelegates} 
                        />
                    </div>

                    <div className='column'>
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Terminaler"
                            
                            onClick={() => props.updateSettings({...props.settings, displayTerminalUsers: !props.settings.displayTerminalUsers})} 
                            isActive={props.settings.displayTerminalUsers} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Opter link"
                            
                            onClick={() => props.updateSettings({...props.settings, displayOpterLink: !props.settings.displayOpterLink})} 
                            isActive={props.settings.displayOpterLink} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Kö"
                            
                            onClick={() => props.updateSettings({...props.settings, displayScheduledEvents: !props.settings.displayScheduledEvents})} 
                            isActive={props.settings.displayScheduledEvents} 
                        />
                    </div>

                    <div className='column'>
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Rutter"
                            
                            onClick={() => props.updateSettings({...props.settings, displayRoutes: !props.settings.displayRoutes})} 
                            isActive={props.settings.displayRoutes} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Resursförslag"
                            
                            onClick={() => props.updateSettings({...props.settings, displayUserSuggestions: !props.settings.displayUserSuggestions})} 
                            isActive={props.settings.displayUserSuggestions} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="uppdragsförslag"
                            
                            onClick={() => props.updateSettings({...props.settings, displayMissionSuggestions: !props.settings.displayMissionSuggestions})} 
                            isActive={props.settings.displayMissionSuggestions} 
                        />
                    </div>

                    <div className='column'>
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Filter"
                            
                            onClick={() => props.updateSettings({...props.settings, displayFilters: !props.settings.displayFilters})} 
                            isActive={props.settings.displayFilters} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Ändringar"
                            
                            onClick={() => props.updateSettings({...props.settings, displayChanges: !props.settings.displayChanges})} 
                            isActive={props.settings.displayChanges} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Färjor"
                            
                            onClick={() => props.updateSettings({...props.settings, displayFerries: !props.settings.displayFerries})} 
                            isActive={props.settings.displayFerries} 
                        />
                    </div>

                    <div className='column'>
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Meddelanden"
                            
                            onClick={() => props.updateSettings({...props.settings, displayMessages: !props.settings.displayMessages})} 
                            isActive={props.settings.displayMessages} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Karta"
                            
                            onClick={() => props.updateSettings({...props.settings, displayMap: !props.settings.displayMap})} 
                            isActive={props.settings.displayMap} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Översiktskarta"
                            
                            onClick={() => props.updateSettings({...props.settings, displayOverviewMap: !props.settings.displayOverviewMap})} 
                            isActive={props.settings.displayOverviewMap} 
                        />
                    </div>

                    <div className='column'>
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Rutt optimeringar"
                            
                            onClick={() => props.updateSettings({...props.settings, displayrouteOptimize: !props.settings.displayrouteOptimize})} 
                            isActive={props.settings.displayrouteOptimize} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Sammlastningar"
                            
                            onClick={() => props.updateSettings({...props.settings, displayCollectiveLoading: !props.settings.displayCollectiveLoading})} 
                            isActive={props.settings.displayCollectiveLoading} 
                        />
                    </div>

                </div>
            </div>
        </div>
    );
}


export default DisplayTab;