
import { BinRecycleFull20Regular, CurrencyDollarEuro20Regular, Document20Regular, Save20Regular, TrayItemRemove20Regular } from "@fluentui/react-icons";
import React from "react";

interface IMissionInfoProps {
    deliveryInfo: Record<string, any>[];
}


const MissionStepsInfo = (props: IMissionInfoProps) => {



    return (
        <div className='row' style={{ flex: 1 }}>
            <div className="orderSection" style={{ flexDirection: "column" }}>
                <h3>Uppdrag</h3>
                <div>
                    <div className="row">
                        <button className="iconButton">
                            <div className="align">
                                <Document20Regular />
                                Ett uppdrag
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <TrayItemRemove20Regular />
                                Automatfördelning
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <Save20Regular />
                                Spara
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <BinRecycleFull20Regular />
                                Ta Bort
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <CurrencyDollarEuro20Regular />
                                Beräkna resultat
                            </div>
                        </button>
                        <select value={1} className="elongatedSelect">
                            <option value={1}></option>
                        </select>
                        <div className="column">
                            <div className="row">
                                <div>Intäkt:</div>
                                <div>0 kr</div>
                            </div>
                            <div className="row">
                                <div>Kostnad:</div>
                                <div>0 kr</div>
                            </div>
                            <div className="row">
                                <div>Resultat:</div>
                                <div>0 kr</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    {props.deliveryInfo && props.deliveryInfo.length > 0 ? (
                        props.deliveryInfo.map((step, index) => (
                        <React.Fragment key={index}>
                            <div className="delivery-leg" style={{ padding: "0 8px" }}>
                                {step.name || "Unnamed Leg"}
                            </div>

                            {step.endTerminal && (
                            <div className="delivery-terminal" style={{ padding: "0 8px" }}>
                                <div>{step.endTerminal.code}</div>
                                <div>{step.endTerminal.name}</div>
                            </div>
                            )}
                        </React.Fragment>
                        ))
                    ) : (
                        <div>No Delivery Info</div>
                    )}
                </div>
            </div>
        </div>
    )
}


export default MissionStepsInfo;