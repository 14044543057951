import { ComputedResult } from "../models/ComputedResult";
import { LocationData } from "../models/LocationData";
import { ConversationResult } from "../models/messages";
import { PassiveDataResult } from "../models/PassiveDataResult";
import { opterApi } from "./api";


export const getPassiveData = async () => {
  const res = await opterApi.get<PassiveDataResult>("passiveData");
  return res.data;
};

export const getActiveUsers = async () => {
  const res = await opterApi.get<LocationData[]>("activeUsers");
  return res.data;
};

export const getActiveUser = async (id: number) => {
  const res = await opterApi.post<LocationData>("activeUser", { id });
  return res.data;
};

export const getUnDistributedOrders = async () => {
  const res = await opterApi.get<Record<string, any>[]>("unDistributedOrders");
  return res.data;
};

export const getDistributedOrders = async () => {
  const res = await opterApi.get<Record<string, any>[]>("distributedOrders");
  return res.data;
};

export const getLoadedOrders = async () => {
  const res = await opterApi.get<Record<string, any>[]>("loadedOrders");
  return res.data;
};

export const getDeliveredOrders = async () => {
  const res = await opterApi.get<Record<string, any>[]>("deliveredOrders");
  return res.data;
};

export const getComputedExtras = async (kolliid: string) => {
  const res = await opterApi.post<ComputedResult>("getOfflineComputedExtras", { kolliid });
  return res.data;
};

export const getConversations = async () => {
  const res = await opterApi.get<ConversationResult>("conversations");
  return res.data;
};

export const getOrderInfo = async (orderNr: string, archive: boolean) => {
  const res = await opterApi.post<any>("orderInfo", { orderNr, archive });
  return res.data;
};