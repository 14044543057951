import moment from "moment";
import { bindActionCreators } from "redux";
import { stepIndexes } from "../helpers/TranslationMaps";
import { CurrentOrderData } from "../models/CurrentOrderData";
import { Department } from "../models/Department";
import { MessageUpdate } from "../models/update/message";
import { actionCreators } from "../state";
import { RootState, store } from "../state/store";
import { OpterDateNow } from "./format";
import { getActiveUser, getComputedExtras } from "./lookupService";

export let socket: WebSocket | undefined;

const enum EventType {
    insert = "insert",
    update = "update",
    delete = "delete"
}

const tablematch: RegExp = /^\{"\s*([^"]+)"/im;
const eventmatch: RegExp = /"event":"([^"]+)"/im;

export function initSocketStream(token: string | undefined, department: Department, setActiveOrders: React.Dispatch<React.SetStateAction<CurrentOrderData | undefined>>) {
    if (!token) return;

    if (socket) {
        if (socket.readyState === socket.OPEN) {
            console.log("closing current socket");
            socket.close();
        }

        socket.onmessage = null; // Detach the old onmessage handler
        socket.onerror = null;
        socket.onclose = null;
    }

    const url: string = "wss://websocket.lastbilsstation.se";

    const requestTables: string[] = ["offline", "applog", "apilog", "currentLocations", "msgMessages", "msgMembers"];
    const protocals: string[] = [token, "SendAll"];

    socket = new WebSocket(url, protocals);


    const state = store.getState() as RootState;

    const subscribeMsg = {
        "type": "subscribe",
        "tables": requestTables,
        "filters": {
          "offline": {
            "SQL_EVENT_TYPE": {
              "op": "==",
              "value": "insert"
            },
          },
          "applog": {
            "department": {
                "op": "==",
                "value": department?.departmentCode,
            },
            "function": {
              "op": "IN",
              "value": ["ApprovedDelivery", "ApprovedPickup", "noApproval", "unload", "loading", "arrival"],
            },
            "SQL_EVENT_TYPE": {
              "op": "==", 
              "value": "insert" 
            },
          },
          "apilog": {
            "department": {
                "op": "==",
                "value": department?.departmentCode,
            },
            "function": {
              "op": "==",
              "value": "DREQ",
            },
            "SQL_EVENT_TYPE": {
              "op": "==", 
              "value": "insert" 
            },
          },
          "typingStatus": {
            "userName": {
              "op": "!=",
              "value": state.auth?.auth?.login?.username,
            },
          },
        },
      };

    socket.onopen = (event) => {
        console.log("Connected to messageStream with", requestTables.length, "tables", OpterDateNow());

        console.log("sending subscribe message");
        socket?.send(JSON.stringify(subscribeMsg));
    };

    socket.onmessage = async (event: MessageEvent<string>) => {

        const table = tablematch.exec(event.data);
        const eventType = eventmatch.exec(event.data);

        if (!table || table[1] === undefined) return;
        if (!eventType || eventType[1] === undefined) return;

        // console.log(table[1], eventType[1]);

        if (table[1] === "offline") {
            if (eventType[1] != EventType.insert) return;

            let data: any = JSON.parse(event.data).offline.changes;

            const depAdress = /([a-zåäö]+\s*\d+)\,.*\d+\s*\d+\s*([a-zåäö]+)/im.exec(department.departmentAdress.toLowerCase());

            if (depAdress == null) return;

            if (depAdress[1] != (data.avsAdrHouseNr as string).toLocaleLowerCase() && depAdress[2] != (data.avsPostOrt as string).toLocaleLowerCase() && depAdress[1] != (data.recAdressHouseNr as string).toLocaleLowerCase() && depAdress[2] != (data.recPostOrt as string).toLocaleLowerCase()) {
                console.log("order not for this department", data.avsAdrHouseNr, data.avsPostOrt, data.recAdressHouseNr, data.recPostOrt);
                return;
            }
            
            console.log("offline", data);

            const computed = await getComputedExtras(data.kolliid);

            console.log("computed extras", computed);

            let delivery = "0";
            
            if (depAdress != null) {
                delivery = depAdress[1] == (data.avsAdrHouseNr as string).toLocaleLowerCase() && depAdress[2] == (data.avsPostOrt as string).toLocaleLowerCase() ? "1" : "0";
            }

            data = { 
                ...data, 
                created: (data.created as string | undefined)?.replace("T", " "), 
                recPostnr: data.RecPostnr, 
                recadressHouseNr: data.recAdressHouseNr,
                arrivalDate: computed.arrivalDate, 
                delivery: delivery,
                pod: computed.pod,
            };

            data.RecPostnr = undefined;
            data.id = undefined;

            console.log("final", data, data.delivery);

            if (data.vehicle !== null) {
                setActiveOrders((prev) => ({ ...prev, distributed: [...(prev?.distributed || []), data] }));
            } else {
                setActiveOrders((prev) => ({ ...prev, unDistributed: [...(prev?.unDistributed || []), data] }));
            }

        } else if (table[1] === "currentLocations") {
            if (eventType[1] == EventType.delete) return;

            const data = JSON.parse(event.data);

            // console.log("currentLocations", eventType[1], data.currentLocations.changes.uid);

            const addActiveData = bindActionCreators(actionCreators, store.dispatch).addActiveData;
            
            const users = (store.getState() as RootState).activeData.usePositions.filter((noti) => moment().diff(moment(noti.timestamp), 'minutes') < 60);

            if (eventType[1] == EventType.update) {

                const index = users.findIndex((user) => user.uid == data.currentLocations.changes.uid);

                if (index === -1) {

                    console.log("user not found, adding", data.currentLocations.changes);

                    try {
                        const respons = await getActiveUser(data.currentLocations.changes.uid);

                        users.push(respons);
                    } catch (e) {
                        console.log("error", e);
                    }

                } else {
                    const user = { ...users[index], ...data.currentLocations.changes };

                    users[index] = user;
                }

                addActiveData({ usePositions: users });
            } else if (eventType[1] == EventType.insert) {

                try {
                    const respons = await getActiveUser(data.currentLocations.changes.uid);

                    users.push(respons);

                    addActiveData({ usePositions: users });
                } catch (e) {
                    console.log("error", e);
                }
            }

        } else if (table[1] === "applog") {
            if (eventType[1] != EventType.insert) return;

            let data = JSON.parse(event.data).applog.changes;

            if (data.department != department.departmentCode) {
                console.log("order not for this department", data.department);
                return;
            }

            // const appvehicle = (data.vehicle as string).toLocaleUpperCase();

            // const vehicle = vehicles.find((vehicle) => vehicle.vehicleName.toLocaleUpperCase() == appvehicle);
            
            // if (!vehicle) {
            //     console.log("order not for this department", vehicle);
            //     return;
            // }
            
            console.log("applog", data);

            const applogFunction = (data.function as string).toLocaleLowerCase();

            if (applogFunction == "approveddelivery" || applogFunction == "approvedpickup") {
                const acceptedOrderNr = data.trackId;
                const delivery = applogFunction == "approveddelivery" ? "1": "0";

                setActiveOrders((prev) => {

                    const orderIndex = prev?.distributed?.findIndex((order) => acceptedOrderNr == order.orderNr);

                    if (orderIndex !== undefined && orderIndex !== -1 && prev?.distributed !== undefined) {

                        prev?.loaded?.push({ ...prev.distributed[orderIndex], delivery, pod: stepIndexes.loaded });

                        prev.distributed = prev?.distributed?.filter((order, index) => index != orderIndex) || [];

                        return prev;
                    }

                    const loadedOrderIndex = prev?.loaded?.findIndex((order) => acceptedOrderNr == order.orderNr);

                    if (loadedOrderIndex !== undefined && loadedOrderIndex !== -1 && prev?.loaded !== undefined && prev.loaded[loadedOrderIndex].delivery != delivery) {

                        prev.loaded[loadedOrderIndex].delivery = delivery;

                        return prev;
                    }

                    return prev;
                });

                return;
            } else if (applogFunction == "noapproval") {
                const deniedOrderNr = data.trackId;

                setActiveOrders((prev) => {

                    const distributed = prev?.distributed?.findIndex((order) => deniedOrderNr == order.orderNr);

                    if (distributed !== undefined && distributed !== -1 && prev?.distributed !== undefined) {

                        prev?.unDistributed?.push({ ...prev.distributed[distributed], pod: stepIndexes.denied });

                        prev.distributed = prev?.distributed?.filter((order) => order.orderNr != deniedOrderNr) || [];

                        return prev;
                    }

                    const loaded = prev?.loaded?.findIndex((order) => deniedOrderNr == order.orderNr);

                    if (loaded !== undefined && loaded !== -1 && prev?.loaded !== undefined) {

                        prev?.unDistributed?.push({ ...prev.loaded[loaded], pod: stepIndexes.denied });

                        prev.loaded = prev?.loaded?.filter((order) => order.orderNr != deniedOrderNr) || [];

                        return prev;
                    }

                    return prev;
                });

                return;
            }

            const delivery = applogFunction === "unload" ? "1": "0";

            const computed = await getComputedExtras(data.trackId);

            if (!computed || !computed?.orderNr) return;

            console.log("computed extras", computed);

            data = { ...data, ...computed, delivery };

            console.log("final", data);

            if (applogFunction == "unload") {
                setActiveOrders((prev) => {

                    const orderIndex = prev?.loaded?.findIndex((order) => computed.orderNr == order.orderNr);

                    if (orderIndex !== undefined && orderIndex !== -1 && prev?.loaded !== undefined) {

                        prev?.delivered?.push({ ...prev.loaded[orderIndex], pod: stepIndexes.delivered });

                        prev.loaded = prev.loaded.filter((order) => order.orderNr != computed.orderNr) || [];
                    }

                    return prev;
                });
            } else if (applogFunction == "loading") {
                setActiveOrders((prev) => {

                    const orderIndex = prev?.loaded?.findIndex((order) => computed.orderNr == order.orderNr);

                    if (orderIndex !== undefined && orderIndex !== -1 && prev?.loaded !== undefined) {

                        prev.loaded = prev.loaded.filter((order, index) => index != orderIndex) || [];
                    }

                    return prev;
                });
            } else if (applogFunction == "arrival") {
                setActiveOrders((prev) => {

                    const unDistributed = prev?.unDistributed?.findIndex((order) => computed.orderNr == order.orderNr);

                    if (unDistributed !== undefined && unDistributed !== -1 && prev?.unDistributed !== undefined) {
                        prev.unDistributed[unDistributed] = { ...prev.unDistributed[unDistributed], arrivalDate: data.created };

                        return prev;
                    }

                    const distributed = prev?.distributed?.findIndex((order) => computed.orderNr == order.orderNr);

                    if (distributed !== undefined && distributed !== -1 && prev?.distributed !== undefined) {
                        prev.distributed[distributed] = { ...prev.distributed[distributed], arrivalDate: data.created  };
                    }

                    return prev;
                });
            }

        } else if (table[1] === "apilog") {
            if (eventType[1] != EventType.insert) return;

            let data = JSON.parse(event.data).apilog.changes;

            if (data.department != department.departmentCode) {
                console.log("order not for this department", data.department);
                return;
            }

            const apiFunction = (data.function as string).toLocaleUpperCase();

            console.log("apilog", data);

            if (apiFunction != "dreq") return;

            const computed = await getComputedExtras(data.TrackId);

            console.log("computed extras", computed);

            data = { ...data, ...computed };

            console.log("final", data);

            setActiveOrders((prev) => {

                const unDistributed = prev?.unDistributed?.findIndex((order) => computed.orderNr == order.orderNr);

                if (unDistributed !== undefined && unDistributed !== -1 && prev?.unDistributed !== undefined) {
                    prev.unDistributed[unDistributed] = { ...prev.unDistributed[unDistributed], arrivalDate: data.created };

                    return prev;
                }

                const distributed = prev?.distributed?.findIndex((order) => computed.orderNr == order.orderNr);

                if (distributed !== undefined && distributed !== -1 && prev?.distributed !== undefined) {
                    prev.distributed[distributed] = { ...prev.distributed[distributed], arrivalDate: data.created };
                }

                return prev;
            });

        } else if (table[1] === "msgMessages") {

            const messageUpdate = (JSON.parse(event.data) as MessageUpdate).msgMessages.changes;

            console.log(`Message data`, messageUpdate.conversationId, messageUpdate.text /*, OpterDateNow()*/);

            const state = (store.getState() as RootState);

            if (messageUpdate.uid == state.auth?.auth.login.uid) {
                console.log("message was from self, ignoring");
                return;
            }

            const messagedata = state.messageData;

            const convoIndex = (messagedata.conversations || []).findIndex(convo => convo.conversationId == messageUpdate.conversationId);

            console.log("coversation , id:", messageUpdate.conversationId, "found at index", convoIndex, "convos", (messagedata.conversations || []).map(convo => convo.conversationId));

            if (convoIndex !== -1) {

                const addMessages = bindActionCreators(actionCreators, store.dispatch).addMessages;

                if (eventType[1] == EventType.update) {
                    const convos = messagedata.conversations || [];

                    const messageIndex = convos[convoIndex].messages.findIndex((item) => item.id == messageUpdate.id);

                    if (messageIndex != -1) {
                        convos[convoIndex].messages[messageIndex] = messageUpdate;

                        addMessages({ conversations: convos });
                    }

                    return;
                } else if (eventType[1] == EventType.delete) {

                    const convos = messagedata.conversations || [];

                    console.log("Deleting message", messageUpdate.id);

                    convos[convoIndex].messages.filter((message) => message.id != messageUpdate.id);

                    addMessages({ conversations: convos });

                    return;
                }

                if (!messagedata) return;

                // const senderText = messageUpdate.uid != -1 ? `${messagedata.conversations![convoIndex].members.find((member) => member.uid == messageUpdate.uid)?.username ?? state.passiveData?.users?.find((user) => user.uid == messageUpdate.uid)?.username ?? "okänd"}: `: "";

                // const sender = messagedata.conversations![convoIndex].members.find(member => member.uid == messageUpdate.uid)?.username || state.passiveData.users?.find(user => user.id == messageUpdate.uid)?.username || "okänd";

                messagedata.conversations![convoIndex] = { ...messagedata.conversations![convoIndex], messages: messagedata.conversations![convoIndex].messages.concat(messageUpdate) };

                addMessages({ conversations: messagedata.conversations });
            }
        } else {
            console.log("Accepted event not mapped", event.data);
        }
    };

    socket.onerror = (event) => {
        console.log("MessageStream error:", event);
    };

    socket.onclose = (event) => {
        console.log("Disconnected from the server", event);

        if (event.reason == "") {
            setTimeout(() => {
                initSocketStream(token, department, setActiveOrders);
            }, 500);
        }
    };
}