import { useState } from "react";
import { columnLableMap, columnTypeMap, columnTypes, customerTypeMap, stepIndexColorMap, stepIndexLableMap, stepIndexes, timeColorMap, timeWindow } from "../helpers/TranslationMaps";
import { formatASCII } from "../Services/format";
import ColumnSorting, { IColumnSearch, IColumnSorting } from "./columnSorting";
import { DotLoadingIndicator } from "./loadingIndicator";





export interface IUnivertalTableProps {
    columns: string[];
    selectedItem: string | undefined;
    items: Record<string, any>[] | undefined;
    onTap: ((item: any) => void) | undefined;
    onDoubleTap?: ((item: any) => void) | undefined;
}




const UniversalTable = (props: IUnivertalTableProps) => {
    const [search, setSearch] = useState<IColumnSearch | undefined>(undefined);
    const [sorting, setSorting] = useState<IColumnSorting | undefined>(undefined);

    const nowTime = Date.now();

    return (
        <div>
            <table className="general-table">
                <thead>
                    <tr>
                        {props.columns.map((column) => (
                            <th onClick={() => setSorting((prev) => ({ column: column, descending: prev?.column == column ? !prev?.descending: false }))}>{columnLableMap[column] + (sorting !== undefined && sorting?.column == column ? (sorting.descending ? " ↓": " ↑"): "")}</th>
                        ))}
                    </tr>
                    <tr>
                        {props.columns.map((column) => (
                            <ColumnSorting column={column} onSearchChange={(search) => setSearch(search)} onSortChange={(sorting) => setSorting(sorting)} />
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.items?.filter((item: any) => search === undefined || (item[search.column] || "").toString().toLowerCase().includes(search.search)).sort((a: any, b: any) => sorting === undefined ? 0 : ((a[sorting.column] || "").toString().localeCompare((b[sorting.column] || "").toString()) * (sorting.descending ? -1: 1))).map((item: Record<string, any>) => {
                        const diff = nowTime - Date.parse(item.created);

                        const custNrColor = customerTypeMap[item.custNr];
                        const denied = item.stepIndex === stepIndexes.denied;

                        return (
                            <tr className={item.orderNr == props.selectedItem ? "selectedRow": ""} onClick={props.onTap !== undefined ? () => props.onTap!(item): undefined} onDoubleClick={props.onDoubleTap !== undefined ? () => props.onDoubleTap!(item): undefined}>
                                {props.columns.map((column: string) => {

                                    let value = item[column];

                                    if (value === undefined || value === null) {
                                        return <td></td>;
                                    }

                                    const type = columnTypeMap[column] || columnTypes.text;

                                    switch (type) {
                                        case columnTypes.startDate:
                                            value = (value as string | undefined)?.split(" ", 2)[0]?.replace(/-00$/, "");
                                            break;
                                        case columnTypes.endDate:
                                            value = (value as string | undefined)?.split("T", 2)[1]?.replace(/-00$/, "");
                                            break;
                                        // case columnTypes.fulldate:
                                        //     value = value?.replace(/-00$/, "");
                                        //     break;
                                        case columnTypes.float:
                                            value = parseFloat(value);
                                            if (value > 0) {
                                                value = value.toFixed(1);
                                            } else {
                                                value = "";
                                            }
                                            break;
                                        case columnTypes.int:
                                            value = parseInt(value);
                                            if (value < 0 || isNaN(value)) {
                                                value = "";
                                            }
                                            break;
                                        case columnTypes.text:
                                            if (value) {
                                                value = formatASCII(value);
                                            } else {
                                                value = "";
                                            }
                                            break;
                                        default:
                                            break;
                                    }

                                    switch (column) {
                                        case "custNr":
                                            return <td style={{ backgroundColor: custNrColor, color: custNrColor && "#ffffff" }}>{value}</td>; 
                                        case "created":
                                            return <td style={{ backgroundColor: diff > timeWindow ? timeColorMap["passed"]: undefined }}>{value?.split(" ")[0]?.replace(/-00$/, "")}</td>
                                        case "stepIndex":
                                            return <td style={{ backgroundColor: stepIndexColorMap[item.stepIndex], color: denied ? "#ffffff": undefined }}>{stepIndexLableMap[value]}</td>
                                        default:
                                            return <td>{value}</td>;
                                    }
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {props.items === undefined && <DotLoadingIndicator />}
        </div>
    )
}


export default UniversalTable;