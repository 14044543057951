// StatusMenue.tsx

import React from "react";
import { stepIndexes } from "../helpers/TranslationMaps";
import { CurrentOrderData } from "../models/CurrentOrderData";
import "../styles/styles.css";

interface IStatusMenueProps {
    activeOrders: CurrentOrderData | undefined;
}

const StatusMenue: React.FC<IStatusMenueProps> = ({ activeOrders }) => {

    let messageCount = 0;
    let deniedCount = 0;
    let pendingCount = 0;
    let linkMessageCount = 0;

    const orderCount = activeOrders?.unDistributed?.length || 0;

    for (let i = 0; i < orderCount; i++) {
        if (activeOrders!.unDistributed![i].pod == stepIndexes.denied) {
            deniedCount++;
        }
    }

    return (
        <div className='messageSection'>
            <div className='messageStat'>
                <div className="statusText">Meddelanden</div>
                <div className="statusText">{messageCount}</div>
            </div>
            <div className='messageStat'>
                <div className="statusText">Nekade Uppdrag</div>
                <div className="statusText">{deniedCount}</div>
            </div>
            <div className='messageStat'>
                <div className="statusText">Väntande Förändringar</div>
                <div className="statusText">{pendingCount}</div>
            </div>
            <div className='messageStat'>
                <div className="statusText">Link Meddelanden</div>
                <div className="statusText">{linkMessageCount}</div>
            </div>
        </div>
    );
}

export default StatusMenue;
