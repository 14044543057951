


interface IOrderFormProps {
    orderData: any;
}

const CustomerInfo = (props: IOrderFormProps) => {

  return (
    <div className='row'>
        <div className="orderSection">
            <h3>Kund</h3>
            <div>
                <label>KundKod:</label>
                <label>Kundref:</label>
                <label>Littera/Projekt:</label>
                <label>Betalare/Telefonnr:</label>
            </div>
            <div>
                <div>
                    <input type="text" name="customerId" value={props.orderData.custNr} readOnly/>
                    <input type="text" name="customerId" value={props.orderData.custName} readOnly/>
                </div>
                <div>
                    <input type="text" name="customerId" value={"EDI"} readOnly/>
                </div>
                <div>
                    <select name="customerId" >
                        <option value={1}></option>
                    </select>
                    <select name="customerId" >
                        <option value={1}></option>
                    </select>
                </div>
                <div>
                    <select name="customerId" value={1}>
                        <option value={1}>Avsändare</option>
                        <option value={1}>Mottagare</option>
                    </select>
                    <select name="customerId" >
                        <option value={1}></option>
                    </select>
                </div>
            </div>
        </div>
        <div className="orderSection">
            <h3>Kundinformation</h3>
            <div>
                <div>
                    <div>{props.orderData.custNr}</div>
                    <button name="customerId" style={{ height: "50%", width: "4rem" }}>{"  -  "}</button>
                </div>
                <div>
                    <textarea name="customerId" style={{ height: "132%", marginTop: "-12.5%" }} value={props.orderData.custName} readOnly/>
                </div>
            </div>
        </div>
        <div className="orderSection">
            <h3>Internt meddelande</h3>
            <div>
                <div>
                    <input type="text" name="customerId" value={""} readOnly/>
                </div>
            </div>
        </div>
        <div className="orderSection">
            <h3>Externt meddelande</h3>
            <div>
                <div>
                    <input type="text" name="customerId" value={""} readOnly/>
                </div>
            </div>
        </div>
        <div className="orderSection">
            <h3>Leveransanvisning</h3>
            <div>
                <div>
                    <textarea name="customerId" value={props.orderData.levanvisning} readOnly/>
                </div>
            </div>
        </div>
        <div className="orderSection">
            <h3>Inställningar</h3>
            <div>
                <div>
                    <select name="customerId" value={1}>
                        <option value={1}>Stor skärm</option>
                    </select>
                </div>
                <div>
                    <select name="customerId" value={1}>
                        <option value={1}>Skelelfteå lastbilsstation</option>
                    </select>
                </div>
                <div>
                    <select name="customerId" value={1}>
                        <option value={1}>Alla</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
  );
};

export default CustomerInfo;
