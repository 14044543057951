import { BinRecycleFull20Regular, Copy20Regular, Document28Regular, DocumentSearch20Regular, DocumentText20Regular, MailAttach20Regular, SubtractCircle20Regular } from "@fluentui/react-icons";
import { round } from "lodash";
import React from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import { funcationTypeMap } from "../../../helpers/TranslationMaps";
import { formatIntSize } from "../../../Services/format";
import { RootState } from "../../../state/store";
import "../../../styles/OrderStyles.css";


interface IOrderData {
    orderData: any;
    events: Record<string, any>[];
}

interface IFile {
    name: string;
    type: string;
    shippingNr?: string;
    size: string;
    created?: string;
}
  
const KolliInfo = (props: IOrderData) => {

    const departments = useSelector((state: RootState) => state.passiveData.departments);

    const [files, setFiles] = React.useState<IFile[]>([]);

    const onDropedFile = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        setFiles((prev) => [
            ...prev, 
            ...acceptedFiles.map<IFile>((file) => ({
                name: file.name, 
                type: file.name.split(".").pop() || "okänd", 
                shippingNr: props.orderData.freightBill,
                size: formatIntSize(file.size), 
                created: new Date(file.lastModified).toLocaleDateString("sv-SE", {
                    year: 'numeric', 
                    month: '2-digit', 
                    day: '2-digit', 
                    hour: '2-digit', 
                    minute: '2-digit', 
                    second: '2-digit' 
                })
            }))
        ]);
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop: onDropedFile });

    return (
      <div className='row' style={{ flex: 1 }}>
        <div className="orderSection">
            <h3>Kollin</h3>
            <div className="tableContainer">
                <table className="scrollTable">
                    <thead>
                        <tr>
                            <th>Godstyp</th>
                            <th>Kollislag</th>
                            <th>Vikt (kg)</th>
                            <th>Volym (m3)</th>
                            <th>Flakmeter</th>
                            <th>Längd (m)</th>
                            <th>Bredd (m)</th>
                            <th>Höjd (m)</th>
                            <th>Kollinummer</th>
                            <th>Antal ({props.orderData.kolliantal})</th>
                            <th>Senaste status</th>
                            <th>Inaktiverad</th>
                            <th>Pallplats</th>
                            <th>Överlastbar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.orderData.kollids.map((kolli: any) => {

                            const event = props.events?.find((item) => item.kolliid == kolli.kolliid);

                            return (
                                <tr key={kolli}>
                                    <td>{""}</td>
                                    <td>{""}</td>
                                    <td>{(kolli.kollivikt || 0.0).toFixed(2)}</td>
                                    <td>{(round(kolli.kollilength || 0.0) * parseFloat(kolli.kollibredd || 0.0) * parseFloat(kolli.kolliheight || 0.0), 4).toFixed(4)}</td>
                                    <td>{(props.orderData.orderflakmeter || 0.0).toFixed(1)}</td>
                                    <td>{(kolli.kollilength || 0.0).toFixed(1)}</td>
                                    <td>{(kolli.kollibredd  || 0.0).toFixed(1)}</td>
                                    <td>{(kolli.kolliheight || 0.0).toFixed(1)}</td>
                                    <td>{kolli.kolliid}</td>
                                    <td>{props.orderData.kolliantal}</td>
                                    <td>{event && `${funcationTypeMap[event?.function?.toLowerCase()] || ""} ${(departments.find((dep) => dep.departmentCode == event?.department)?.departmentName || "-")} (${event?.created?.replace(/(t)/i, " ")})`}</td>
                                    <td>{"Nej"}</td>
                                    <td>{(props.orderData.orderpallplatser || 0.0).toFixed(1)}</td>
                                    <td>{""}</td>
                                </tr>
                            );
                        }).concat(props.orderData.kollids.length === 0 ? 
                            <tr>
                                {Array.from({ length: 14 }).map((_, index) => (
                                    <td key={index}> </td>
                                ))}
                            </tr>: []
                        )}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="orderSection" style={{ flexDirection: 'column', minWidth: "50rem" }}>
            <h3>Bifogade filer</h3>
            <div className="column" style={{ flex: 1, alignSelf: 'stretch', height: "100%", width: "100%" }}>
                <div className="row" style={{ marginBottom: 4, maxHeight: "2.5rem" }}>
                    <button>
                        <div className="align">
                            <DocumentText20Regular />
                            Ny
                        </div>
                    </button>
                    <button>
                        <div className="align">
                            <BinRecycleFull20Regular />
                            Ta bort
                        </div>
                    </button>
                    <button>
                        <div className="align">
                            <DocumentSearch20Regular />
                            Förhandgranska
                        </div>
                    </button>
                    <button>
                        <div className="align">
                            <MailAttach20Regular />
                            Bifoga fil i e-post
                        </div>
                    </button>
                    <button>
                        <div className="align">
                            <Copy20Regular />
                            Kopiera fil
                        </div>
                    </button>
                </div>
                {files.length > 0 && (
                    <table className="scrollTable" style={{ marginBottom: 10 }}>
                        <thead>
                            <tr>
                                <th>Filnamn</th>
                                <th>Kategori</th>
                                <th>Fraktsedel</th>
                                <th>Storlek</th>
                                <th>Skapad</th>
                            </tr>
                        </thead>
                        <tbody>
                            {files.map((file, index) => (
                                <tr key={index}>
                                    <td>{file.name}</td>
                                    <td>{file.type}</td>
                                    <td>{file.shippingNr}</td>
                                    <td>{file.size}</td>
                                    <td>{file.created}</td>
                                    <SubtractCircle20Regular 
                                        color="red" 
                                        onClick={() => setFiles((prev) => prev.filter((f) => f.name !== file.name))}
                                    />
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                <div {...getRootProps()} style={{ marginLeft: -4, flex: 1, display: 'flex', width: "100%" }}>
                    <input {...getInputProps()} />
                    <div className="fileDrop" style={{ flex: 1, minHeight: '3rem', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <center>
                            <Document28Regular />
                            <div className="fileDropText">Släpp filer här</div>
                        </center>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
};

export default React.memo(KolliInfo);
