import { SubtractCircle20Regular } from "@fluentui/react-icons";
import L, { LatLngExpression } from "leaflet";
import React from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { useSelector } from "react-redux";
import { AppCode } from "../../../models/deviationCode";
import { RootState } from "../../../state/store";

L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

interface IOrderData {
    orderData: any;
    events: Record<string, any>[];
}

  
const PodInfo = (props: IOrderData) => {

    const passiveData = useSelector((state: RootState) => state.passiveData);

    const [mapPosition, setMapPosition] = React.useState<LatLngExpression | undefined>(undefined);

    function showOnMap(event: any) {
        setMapPosition([event.latitude, event.longitute]);
    }

    return (
        <div className='row'>
            <div className="orderSection">
                <h3>Leveransbevis</h3>
                <div className="tableContainer">
                    <table className="scrollTable">
                        <thead>
                            <tr>
                                <th>Typ</th>
                                <th>Datum</th>
                                <th>Användare</th>
                                <th>Förtydligande</th>
                                <th>Kommentar</th>
                                <th>Signatur</th>
                                <th>Bild</th>
                                <th>Subtyp</th>
                                <th>Kolli</th>
                                <th>Anmärkning</th>
                                <th>Antal</th>
                                <th>Visa på karta</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.events.map((event: any) => {

                                const pod: AppCode | undefined = event.podTyp == "1" ? { id: 0, code: 1, type: "delivery", shortdesc: "Levererad" } as AppCode: passiveData.appcodes.find((code) => code.code == event.podTyp);

                                const user = passiveData.users.find((user) => user.id == event.uid);

                                return (
                                    <tr key={event.created}>
                                        <td>{`${pod?.shortdesc} (${pod?.code})`}</td>
                                        <td>{event.created}</td>
                                        <td>{`${user?.username} (${user?.id})`}</td>
                                        <td>{event.nameClarification}</td>
                                        <td>{event.comment}</td>
                                        <td>{event.signature && <img style={{ maxHeight: "7rem" }} src={"data:image/jpeg;base64," + event.signature} />}</td>
                                        <td>{event.attachmentImage && <img style={{ maxHeight: "7rem" }} src={"data:image/jpeg;base64," + event.attachmentImage} />}</td>
                                        <td>{}</td>
                                        <td>{event.kolliid}</td>
                                        <td>{}</td>
                                        <td>{props.orderData.kolliantal}</td>
                                        <td onClick={() => showOnMap(event)}><button style={{ height: "100%", width: "100%" }}>Visa</button></td>
                                    </tr>
                                );
                            }).concat(props.events.length === 0 ?
                                <tr>
                                    {Array.from({ length: 12 }).map((_, index) => (
                                        <td key={index} />
                                    ))}
                                </tr>: []
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {mapPosition && (
                <div className="orderSection" style={{ padding: "0.3rem", paddingTop: "1rem" }}>
                    <h3>Leverans plats</h3>
                    <MapContainer
                        center={mapPosition}
                        zoom={14}
                        zoomControl={false}
                        style={{ height: '13.5rem', width: '13.5rem' }}
                    >
                        <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' maxZoom={18} />
                        <Marker position={mapPosition} />
                    </MapContainer>
                    <SubtractCircle20Regular onClick={() => setMapPosition(undefined)} className="orderSectionClose" />
                </div>
            )}
        </div>
    )
}

export default React.memo(PodInfo);