import { calculateCostSummary } from "../../../helpers/CostSummary";
import "../../../styles/styles.css";
import CostDisplay from "../../costDisplay";
import UniversalTable from "../../UniversalTable";


interface IDeliveriesProps {
    created: Record<string, any>[] | undefined;
    selectedItem: string | undefined;
    filters: string[];
    onSingleTap: (item: any) => void;
    onDoubleTap: (item: any) => void;
}

const ComingDeliveries = (props: IDeliveriesProps) => {

    const createdStats = calculateCostSummary(props.created || []);

    const columns: string[] = [
        "stepIndex",
        "created",
        "recCompany",
        "recadressHouseNr",
        "recPostOrt",
        "recPostnr",
        "extraid5",
        "custNr",
        "custName",
        "avsCompany",
        "avsPostOrt",
        "arrivalDate",
    ];

    return (
        <div style={{ border: "solid 0.0625rem black", alignSelf: "start" }}>
            <div className="column">
                <div>
                    <div className="spacedRow" style={{ padding: "0px" }}>
                        <div className="sectionTitle">Skapade utkörningar</div>
                        <CostDisplay summary={createdStats} />
                    </div>
                    <UniversalTable columns={columns} items={props.created} selectedItem={props.selectedItem} onTap={props.onSingleTap} />
                </div>
            </div>
        </div>
    )
}


export default ComingDeliveries;