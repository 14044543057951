import axios from "axios";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { Auth, AuthResult, Loginresult } from "../models/AuthResult";
import { tokenResult } from "../models/tokenResult";
import { RootState, store } from "../state/store";
import { axiosOptions, opterApi } from "./api";


export const login = async (username: string, password: string): Promise<Loginresult> => {
  try {
    const payload: Auth = {
      username: username,
      password: password,
    };

    const result = await opterApi.post<AuthResult>("login", payload);

    if (!result.data.login.LoginStatus) {
      return { success: false, error: "Fel användarnamn eller lösenord" };
    }

    return { success: true, result: result.data };
  } catch (e) {

    console.log("login error", e);

    return { success: false, error: `${e}` };
  }
}


export const getToken = async () => {
  const auth = (store.getState() as RootState)?.auth;
  const refreshTime = tokenLifetime(auth?.auth.refreshToken);

  console.log("refresh time remaining", refreshTime, "minutes");

  // if(refreshTime <= 30){
  //   console.log("no token left!", "logging out");
  //   Toast.show({
  //     placement: "top",
  //     title: "Utloggning",
  //     description: "Din token har gått ut\nvänligen logga in igen"
  //   });
  //   logOut();
  //   return;
  // } else if (refreshTime <= 120 && navigationRef.current?.getState().routes[0].name !== Routes.login) {
  //   Toast.show({
  //     placement: "top",
  //     title: "Utloggning",
  //     description: "du kommer att bli utloggad snart var berred!"
  //   });
  // }
  
  const tempApi = axios.create(axiosOptions);
  tempApi.interceptors.request.use(
    (config) => {
      try {
        const refreshToken = (store.getState() as RootState).auth?.auth.refreshToken;
        if (!refreshToken) return config;
        config.headers.Authorization = `Bearer ${refreshToken}`;
      } catch (error){
        console.log(error);
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  let res: tokenResult | undefined;
  try {

    res = (await tempApi.get<tokenResult>("renewToken")).data;
  } catch (e){
    console.log("Token error", e);
  }

  return res;
} 


/**
 * @param token represented by a string?
 * @returns returns a tokens remaining lifetime in minutes, 0 if undefined / invalid
 */
export function tokenLifetime(token: string | undefined): number {
  if (!token) return 0.0;

  try {
    const parsedToken = jwt_decode<any>(token);

    const expiration = moment.unix(parsedToken.exp);
    const diference = expiration.diff(moment.now(), "seconds") / 60;

    return diference;
  } catch (e) {
    console.log("token error", e);
    return 0.0;
  }
}
