import { calculateCostSummary } from "../../../helpers/CostSummary";
import CostDisplay from "../../costDisplay";
import DeliveredTable from "./deliveredTable";



interface IDeliveredProps {
    items: Record<string, any>[] | undefined;
    selectedItem: string | undefined;
    filters: string[];
    onSingleTap: (item: any) => void;
}


const Delivered = (props: IDeliveredProps) => {

    const createdStats = calculateCostSummary(props.items || []);

    const columns: string[] = [
        "stepIndex",
        "freightBill",
        "orderNr",
        "created",
        "custNr",
        // "route",
        "avsCompany",
        "avsPostOrt",
        "fromTimePickup",
        "toTimePickup",
        "recCompany",
        "recPostOrt",
        "recPostnr",
        "recFromTimeDeliver",
        "recLastLevTime",
        // "wasDirect",
        // "H",
        // "L1",
        // "L2",
        // "L3",
        "vehicle",
        "service",
        "vehicleType",
        "ordervikt",
        "ordervolume",
        "orderflakmeter",
        "orderpallplatser",
        "cargoType",
        "problem",
    ];

    return (
        <div style={{ border: "solid 0.0625rem black", borderRadius: "3px", padding: 5 }}>
            <div className="column">
                <div className="expand doubleExpanded">
                    <div className="spacedRow" style={{ padding: "0px" }}>
                        <div className="sectionTitle">Levererade ordrar</div>
                        <CostDisplay summary={createdStats} />
                    </div>
                    {/* <UniversalTable columns={columns} items={props.items} selectedItem={props.selectedItem} onTap={props.onSingleTap} /> */}
                    <DeliveredTable items={props.items} selectedItem={props.selectedItem} onSingleTap={props.onSingleTap} filters={props.filters} ></DeliveredTable>
                </div>
            </div>
        </div>
    )
}


export default Delivered;