import { calculateCostSummary } from "../../../helpers/CostSummary";
import CostDisplay from "../../costDisplay";
import UniversalTable from "../../UniversalTable";




interface ITimeLimitProps {
    items: Record<string, any>[] | undefined;
    selectedItem: string | undefined;
    filters: string[];
    onSingleTap: (item: any) => void;
}


const TimeLimit = (props: ITimeLimitProps) => {

    const createdStats = calculateCostSummary(props.items || []);

    const columns: string[] = [
        "stepIndex",
        "orderNr",
        "created",
        "custName",
        "recCompany",
        "recPostOrt",
        "recPostnr",
        "arrivalDate",
        "vehicle",
        "ordervikt",
        "ordervolume",
        "orderflakmeter",
        "orderpallplatser",
        "recLastLevTime",
        "missingCount",
    ];

    return (
        <div style={{ border: "solid 0.0625rem black", borderRadius: "3px", padding: 5 }}>
            <div className="column">
                <div className="expand doubleExpanded">
                    <div className="spacedRow" style={{ padding: "0px" }}>
                        <div className="sectionTitle">Tidspassning</div>
                        <CostDisplay summary={createdStats} />
                    </div>
                    <UniversalTable columns={columns} items={props.items} selectedItem={props.selectedItem} onTap={props.onSingleTap} />
                </div>
            </div>
        </div>
    )
}


export default TimeLimit;