import { calculateCostSummary } from "../../../helpers/CostSummary";
import "../../../styles/styles.css";
import CostDisplay from "../../costDisplay";
import UniversalTable from "../../UniversalTable";


interface IDeliveriesProps {
    created: Record<string, any>[] | undefined;
    distributed: Record<string, any>[] | undefined;
    loaded: Record<string, any>[] | undefined;
    selectedItem: string | undefined;
    filters: string[];
    onSingleTap: (item: any) => void;
    onDoubleTap: (item: any) => void;
}


const Deliveries = (props: IDeliveriesProps) => {

    const createdStats = calculateCostSummary(props.created || []);
    const distributedStats = calculateCostSummary(props.distributed || []);
    const loadedStats = calculateCostSummary(props.loaded || []);

    const createdColumns: string[] = [
        "stepIndex",
        "created",
        "recCompany",
        "recadressHouseNr",
        "recPostOrt",
        "recPostnr",
        "extraid5",
        "custNr",
        "custName",
        "avsCompany",
        "avsPostOrt",
        "arrivalDate",
    ];

    const columns: string[] = [
        "created",
        "recCompany",
        "recadressHouseNr",
        "recPostOrt",
        "recPostnr",
        "extraid5",
        "custNr",
        "custName",
        "avsCompany",
        "avsPostOrt",
        "arrivalDate",
    ];

    return (
        <div style={{ border: "solid 0.0625rem black", borderRadius: "3px", padding: 5 }}>
            <div className="row" style={{ alignItems: 'flex-start' }}>
                <div className="column">
                    <div className="expand doubleExpanded">
                        <div className="spacedRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Skapade utkörningar</div>
                            <CostDisplay summary={createdStats} />
                        </div>
                        <UniversalTable columns={createdColumns} items={props.created} selectedItem={props.selectedItem} onTap={props.onSingleTap} />
                    </div>
                </div>
                <div className="column">
                    <div className="expand">
                        <div className="spacedRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Fördelade</div>
                            <CostDisplay summary={distributedStats} />
                        </div>
                        <UniversalTable columns={columns} items={props.distributed} selectedItem={props.selectedItem} onTap={props.onSingleTap} />
                    </div>
                    <div className="expand">
                        <div className="spacedRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Lastade</div>
                            <CostDisplay summary={loadedStats} />
                        </div>
                        <UniversalTable columns={columns} items={props.loaded} selectedItem={props.selectedItem} onTap={props.onSingleTap} />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Deliveries;