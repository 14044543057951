import { ICostSummary } from "../helpers/CostSummary";


export interface ICostDisplayProps {
    summary: ICostSummary;
    showKollis?: boolean;
}


const CostDisplay = (props: ICostDisplayProps) => {

    return (
        <div className="costSection">
            <div className="costItem">{props.summary.itemCount} st</div>
            {props.showKollis == true && <div className="costItem">{props.summary.kolliCount} kli</div>}
            <div className="costItem">{props.summary.weight.toFixed(1)} kg</div>
            <div className="costItem">{props.summary.prisedWeight.toFixed(1)} kg</div>
            <div className="costItem">{props.summary.cost || "?"} kr</div>
        </div>
    );
}

export default CostDisplay;