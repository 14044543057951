import { calculateCostSummary } from "../../../helpers/CostSummary";
import CostDisplay from "../../costDisplay";
import UniversalTable from "../../UniversalTable";


export interface IComingPickupsDirectProps {
    normal: Record<string, any>[] | undefined;
    direct: Record<string, any>[] | undefined;
    selectedItem: string | undefined;
    filters: string[];
    onSingleTap: (item: any) => void;
    onDoubleTap: (item: any) => void;
}

const ComingPickups = (props: IComingPickupsDirectProps) => {

    const createdNormalStats = calculateCostSummary(props.normal || []);
    const createdDirectStats = calculateCostSummary(props.direct || []);


    const CreatePickupColumns: string[] = [
        "stepIndex",
        "orderNr",
        "created",
        "custNr",
        "avsCompany",
        "avsAdrHouseNr",
        "avsPostOrt",
        "avsPostnr",
        "fromTimePickup",
        "toTimePickup",
        "recCompany",
        "recadressHouseNr",
        "recPostOrt",
        "recPostnr",
        "fromTimePickup",
        "toTimePickup",
        "vehicle",
        "kolliantal",
        "ordervikt",
        "ordervolume",
        "orderflakmeter",
        "orderpallplatser",
        "cargoType",
    ];

    const createdDirectPickupColumns: string[] = [
        "stepIndex",
        "orderNr",
        "created",
        "custNr",
        "avsCompany",
        "avsAdrHouseNr",
        "avsPostOrt",
        "avsPostnr",
        "fromTimePickup",
        "toTimePickup",
        "recCompany",
        "recadressHouseNr",
        "recPostOrt",
        "recPostnr",
        "fromTimePickup",
        "toTimePickup",
        "vehicle",
        "kolliantal",
        "ordervikt",
        "ordervolume",
        "orderflakmeter",
        "orderpallplatser",
        "cargoType",
    ];

    return (
        <div style={{ border: "solid 0.0625rem black", padding: 5 }}>
            <div className="column">
                <div>
                    <div className="spacedRow" style={{ padding: "0px" }}>
                        <div className="sectionTitle">Skapade hämtningar</div>
                        <CostDisplay summary={createdNormalStats} />
                    </div>
                    <UniversalTable columns={CreatePickupColumns} items={props.normal} selectedItem={props.selectedItem} onTap={props.onSingleTap} onDoubleTap={props.onDoubleTap} />
                </div>
                <div>
                    <div className="spacedRow" style={{ padding: "0px" }}>
                        <div className="sectionTitle">Skapade Direktkröningar</div>
                        <CostDisplay summary={createdDirectStats} />
                    </div>
                    <UniversalTable columns={createdDirectPickupColumns} items={props.direct} selectedItem={props.selectedItem} onTap={props.onSingleTap} />
                </div>
            </div>
        </div>
    )
}


export default ComingPickups;