import { calculateCostSummary } from "../../../helpers/CostSummary";
import CostDisplay from "../../costDisplay";
import UniversalTable from "../../UniversalTable";



interface IAllStatusProps {
    items: Record<string, any>[] | undefined;
    selectedItem: string | undefined;
    filters: string[];
    // onRightClick: (item: any) => void;
}


const AllStatus = (props: IAllStatusProps) => {

    const createdStats = calculateCostSummary(props.items || []);

    const columns: string[] = [
        "stepIndex",
        "orderNr",
        "kolliid",
        "extraid2",
        "created",
        "arrivalDate",
        "custNr",
        "avsCompany",
        "avsPostnr",
        "avsPostOrt",
        "recCompany",
        "RecPostnr",
        "recPostOrt",
        "recFromTimeDeliver",
        "recLastLevTime",
        "vehicle",
        "kolliantal",
        "ordervikt",
        "ordervolume",
        "orderflakmeter",
        "orderpallplatser"
    ];

    return (
        <div style={{ border: "solid 0.0625rem black", borderRadius: "3px", padding: 5 }}>
            <div className="column">
                <div className="expand">
                    <div className="spacedRow" style={{ padding: "0px" }}>
                        <div className="sectionTitle">Alla statusar</div>
                        <CostDisplay summary={createdStats} />
                    </div>
                    <UniversalTable columns={columns} items={props.items} selectedItem={props.selectedItem} onTap={undefined} />
                    {/* <AllStatusTable selectedItem={props.selectedItem} items={props.items} onRightClick={undefined} filters={props.filters}></AllStatusTable> */}
                </div>
            </div>
        </div>
    )
}


export default AllStatus;