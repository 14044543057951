

import { Calendar, Settings, Target } from "lucide-react";
import { ViewSettings } from "../../models/ViewSettings";
import IconToggleButton from "../IconToggleButton";


interface MapTabProps {
    settings: ViewSettings;
    updateSettings: React.Dispatch<React.SetStateAction<ViewSettings>>;
}

const MapTab = (props: MapTabProps) => {
    return (
        <div className='topmenue'>
            <div className='smallSection'>
                <div className='spacedRow m-1'>
                    <IconToggleButton 
                        Icon={Target} 
                        text="Centrera vald resurs"
                        column
                        onClick={() => {}} 
                        isActive={false} 
                    />
                    <IconToggleButton 
                        Icon={Settings} 
                        text="Visa trailers"
                        column
                        onClick={() => props.updateSettings({...props.settings, showTrailers: !props.settings.showTrailers})} 
                        isActive={props.settings.showTrailers} 
                    />
                    <IconToggleButton 
                        Icon={Calendar} 
                        text="Separera resurser utan giltig position"
                        column
                        onClick={() => props.updateSettings({...props.settings, separateInvalidPositions: !props.settings.separateInvalidPositions})} 
                        isActive={props.settings.separateInvalidPositions} 
                    />
                </div>
            </div>

            <div className='largeSection'>
                <div className='spacedRow m-1'>
                    <div className="column">
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Visa tilldelade uppdrag för vald resurs"
                            onClick={() => props.updateSettings({...props.settings, showDsipatchedMissionsForSelected: !props.settings.showDsipatchedMissionsForSelected})} 
                            isActive={props.settings.showDsipatchedMissionsForSelected} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Visa förplanerade uppdrag för vald resurs"
                            onClick={() => props.updateSettings({...props.settings, showPlannedMissionsForSelected: !props.settings.showPlannedMissionsForSelected})} 
                            isActive={props.settings.showPlannedMissionsForSelected} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Visa förslagna uppdrag för vald resurs"
                            onClick={() => props.updateSettings({...props.settings, showSuggestedMissionsForSelected: !props.settings.showSuggestedMissionsForSelected})} 
                            isActive={props.settings.showSuggestedMissionsForSelected} 
                        />
                    </div>

                    <div className="column">
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Visa endast dagens uppdrag"
                            onClick={() => props.updateSettings({...props.settings, showOnlyDailyMissions: !props.settings.showOnlyDailyMissions})} 
                            isActive={props.settings.showOnlyDailyMissions} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Visa valda uppdrag"
                            onClick={() => props.updateSettings({...props.settings, showSelectedMissions: !props.settings.showSelectedMissions})} 
                            isActive={props.settings.showSelectedMissions} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Visa alla uppdrag utan resurs"
                            onClick={() => props.updateSettings({...props.settings, showAllMissionsWithoutUser: !props.settings.showAllMissionsWithoutUser})} 
                            isActive={props.settings.showAllMissionsWithoutUser} 
                        />
                    </div>
                </div>
            </div>
            <div className='largeSection'>
                <div className='spacedRow m-1'>
                    <div className="column">
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Visa körordning"
                            onClick={() => props.updateSettings({...props.settings, showDrivingOrder: !props.settings.showDrivingOrder})} 
                            isActive={props.settings.showDrivingOrder} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Visa adressinfomation"
                            onClick={() => props.updateSettings({...props.settings, showAdressInfo: !props.settings.showAdressInfo})} 
                            isActive={props.settings.showAdressInfo} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Visa Linjer"
                            onClick={() => props.updateSettings({...props.settings, showVehicleLines: !props.settings.showVehicleLines})} 
                            isActive={props.settings.showVehicleLines} 
                        />
                    </div>

                    <div className="column">
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Visa förseningar"
                            onClick={() => props.updateSettings({...props.settings, showDelays: !props.settings.showDelays})} 
                            isActive={props.settings.showDelays} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Visa ETA"
                            onClick={() => props.updateSettings({...props.settings, showETA: !props.settings.showETA})} 
                            isActive={props.settings.showETA} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Visa indikator för hämt/lämn"
                            onClick={() => props.updateSettings({...props.settings, showMissionTypeIndicator: !props.settings.showMissionTypeIndicator})} 
                            isActive={props.settings.showMissionTypeIndicator} 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapTab;