import { formatASCII } from "../../../Services/format";



interface IAddressFormProps {
  orderData: any;
}

const AddressInfo = (props: IAddressFormProps) => {
  return (
    <div className='row'>
        <div className="orderSection">
            <h3>Avsändare</h3>
            <div>
                <label>KundKod/KundNr:</label>
                <label>Namn:</label>
                <label>Avdelning:</label>
                <label>Gata/nr:</label>
                <label>PostNr/Ort:</label>
                <label>Tid/Tid:</label>
                <label>Geozon/Priszon:</label>
                <label>Land:</label>
                <label>Kod/Tel:</label>
                <label>E-post/infromation:</label>
            </div>
            <div>
                <div>
                    <input type="text" name="customerId" value={props.orderData.custNr} readOnly/>
                </div>
                <div>
                    <input type="text" name="customerId" value={props.orderData.avsCompany} readOnly/>
                </div>
                <div>
                    <input type="text" name="customerId" value={props.orderData.avsDepartment} readOnly/>
                </div>
                <div>
                    <input type="text" name="customerId" value={formatASCII(props.orderData.avsAdrHouseNr?.split(" ")[0])} readOnly/>
                    <input type="text" name="customerId" value={props.orderData.avsAdrHouseNr?.split(" ")[1]} readOnly/>
                </div>
                <div>
                    <input type="text" name="customerId" value={props.orderData.avsPostnr} readOnly/>
                    <input type="text" name="customerId" value={formatASCII(props.orderData.avsPostOrt)} readOnly/>
                </div>
                <div>
                    <input type="text" name="customerId" value={props.orderData.recFromTimeDeliver?.split("T")[1]?.replaceAll("-", ":").replace(":00", "")} readOnly/>
                    <input type="text" name="customerId" value={props.orderData.recLastLevTime?.split("T")[1]?.replaceAll("-", ":").replace(":00", "")} readOnly/>
                </div>
                <div>
                  <select name="customerId" value={1}>
                    <option value={1}>Ingen</option>
                  </select>
                </div>
                <div>
                  <select name="customerId" value={1}>
                    <option value={1}>Sweden</option>
                  </select>
                </div>
                <div>
                    <input type="text" name="customerId" value={props.orderData.avsPhone} readOnly/>
                    <input type="text" name="customerId" value={props.orderData.avsGateCode} readOnly/>
                </div>
                <div>
                    <input type="text" name="customerId" value={""} readOnly/>
                    <input type="text" name="customerId" value={""} readOnly/>
                </div>
            </div>
        </div>
        <div className="orderSection">
            <h3>Mottagare</h3>
            <div>
                <label>KundKod/KundNr:</label>
                <label>Namn:</label>
                <label>Avdelning:</label>
                <label>Gata/nr:</label>
                <label>PostNr/Ort:</label>
                <label>Tid/Datum/Tid/Datum:</label>
                <label>Geozon/Priszon:</label>
                <label>Land:</label>
                <label>Kod/Tel:</label>
                <label>E-post/infromation:</label>
            </div>
            <div>
                <div>
                    <input type="text" name="customerId" value={props.orderData.custNr} readOnly/>
                </div>
                <div>
                    <input type="text" name="customerId" value={props.orderData.recCompany} readOnly/>
                </div>
                <div>
                    <input type="text" name="customerId" value={props.orderData.recDepartment} readOnly/>
                </div>
                <div>
                    <input type="text" name="customerId" value={props.orderData.recAdressHouseNr?.split(" ")[0]} readOnly/>
                    <input type="text" name="customerId" value={props.orderData.recAdressHouseNr?.split(" ")[1]} readOnly/>
                </div>
                <div>
                    <input type="text" name="customerId" value={props.orderData.RecPostnr} readOnly/>
                    <input type="text" name="customerId" value={props.orderData.recPostOrt} readOnly/>
                </div>
                <div>
                    <input type="text" name="customerId" value={props.orderData.recFromTimeDeliver?.split("T")[1]?.replaceAll("-", ":").replace(":00", "")} readOnly/>
                    <input type="date" name="customerId" value={props.orderData.recFromTimeDeliver?.split("T")[0]?.replaceAll("-", ":").replace(":00", "")} readOnly/>
                    <input type="text" name="customerId" value={props.orderData.recLastLevTime?.split("T")[1]?.replaceAll("-", ":").replace(":00", "")} readOnly/>
                    <input type="date" name="customerId" value={props.orderData.recLastLevTime?.split("T")[0]?.replaceAll("-", ":").replace(":00", "")} readOnly/>
                </div>
                <div>
                  <select name="customerId" value={1}>
                    <option value={1}>Ingen</option>
                  </select>
                </div>
                <div>
                  <select name="customerId" value={1}>
                    <option value={1}>Sweden</option>
                  </select>
                </div>
                <div>
                    <input type="text" name="customerId" value={""} readOnly/>
                    <input type="text" name="customerId" value={""} readOnly/>
                </div>
                <div>
                    <input type="text" name="customerId" value={""} readOnly/>
                    <input type="text" name="customerId" value={""} readOnly/>
                </div>
            </div>
        </div>
        <div className="orderSection">
            <h3>Dimensioner</h3>
            <div>
                <label>Kollin:</label>
                <label>Vikt:</label>
                <label>LångGodsLängd:</label>
                <label>Volym:</label>
                <label>Pallplats:</label>
                <label>Flakmeter:</label>
                <label>Prissättningsvikt:</label>
                <label>Avstånd:</label>
                <label>Tid:</label>
                <label>Körtid:</label>
            </div>
            <div>
                <div>
                    <input type="text" name="kolliantal" value={props.orderData.kolliantal || 0.0} readOnly/>
                </div>
                <div>
                    <input type="text" name="ordervikt" value={(props.orderData.ordervikt || 0.0).toFixed(1)} readOnly/>
                </div>
                <div>
                    <input type="text" name="readOnly" value={""} readOnly/>
                </div>
                <div>
                  <input type="text" name="ordervolume" value={(props.orderData.ordervolume || 0.0).toFixed(1)} readOnly/>
                </div>
                <div>
                    <input type="text" name="orderpallplatser" value={(props.orderData.orderpallplatser || 0.0).toFixed(1)} readOnly/>
                </div>
                <div>
                  <input type="text" name="orderflakmeter" value={(props.orderData.orderflakmeter || 0.0).toFixed(1)} readOnly/>
                </div>
                <div>
                  <div>?</div>
                </div>
                <div>
                  <input type="text" name="customerId" value={""} readOnly/>
                </div>
                <div>
                  <input type="text" name="customerId" value={""} readOnly/>
                </div>
                <div>
                  <input type="text" name="customerId" value={""} readOnly/>
                </div>
            </div>
        </div>
        <div className="orderSection">
            <h3>Data</h3>
            <div>
                <label>Fraktsedel:</label>
                <label>Avsändarref:</label>
                <label>Mottagarref:</label>
                <label>Godstyp:</label>
                <label>region:</label>
                <label>Slinga:</label>
                <label>AWB:</label>
                <label>Tull id:</label>
                <label>HAWB:</label>
                <label>IG nr:</label>
                <label>Ombud:</label>
            </div>
            <div>
                <div>
                    <input type="text" name="customerId" value={"?"} readOnly/>
                </div>
                <div>
                    <input type="text" name="customerId" value={"?"} readOnly/>
                </div>
                <div>
                    <input type="text" name="customerId" value={"?"} readOnly/>
                </div>
                <div>
                  <input type="text" name="customerId" value={""} readOnly/>
                </div>
                <div>
                    <input type="text" name="customerId" value={props.orderData.avsPostOrt} readOnly/>
                </div>
                <div>
                  <input type="text" name="customerId" value={props.orderData.extraid5?.toLowerCase().includes("tidspass") ? "Tidspass": props.orderData.slinga} readOnly/>
                </div>
                <div>
                  <input type="text" name="customerId" value={""} readOnly/>
                </div>
                <div>
                  <input type="text" name="customerId" value={""} readOnly/>
                </div>
                <div>
                  <input type="text" name="customerId" value={""} readOnly/>
                </div>
                <div>
                  <input type="text" name="customerId" value={props.orderData.custNr} readOnly/>
                </div>
                <div>
                  <input type="text" name="customerId" value={props.orderData.extraid5} readOnly/>
                </div>
            </div>
        </div>
        <div className="orderSection">
            <h3>Förslag på förare</h3>
            <div>
              <label><input type="checkbox" name="example" value="option1" /></label>
              <label><input type="checkbox" name="example" value="option1" /></label>
              <label><input type="checkbox" name="example" value="option1" /></label>
              <label><input type="checkbox" name="example" value="option1" /></label>
              <label><input type="checkbox" name="example" value="option1" /></label>
              <label>Fakturstatus:</label>
              <label>Ursprunglig order:</label>
              <label>Order typ:</label>
              <label>Status:</label>
              <label>Skapad:</label>
              <label><input type="checkbox" name="example" value="option1" /></label>
              <label><input type="checkbox" name="example" value="option1" /></label>
            </div>
            <div>
                <div>
                  <div>Klar för trafikledning</div>
                </div>
                <div>
                  <div>Klar för fakturering/avräkning</div>
                </div>
                <div>
                  <div>Faktureras ej</div>
                </div>
                <div>
                  <div>Avräknas ej</div>
                </div>
                <div>
                  <div>Göm tills skannad</div>
                </div>
                <div>
                  <div>{"Att faktureras"}</div>
                </div>
                <div>
                  <div>{"0"}</div>
                </div>
                <div>
                  <div>{"EDI"}</div>
                </div>
                <div>
                  <div>{"Lastad"}</div>
                </div>
                <div>
                  <div>{props.orderData.created}</div>
                </div>
                <div>
                  <div>Nollställ orderdatum vid scanning</div>
                </div>
                <div>
                  <div>Ingen EDI-export till terminal</div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default AddressInfo;
