
import { Settings } from "lucide-react";
import { ViewSettings } from "../../models/ViewSettings";
import IconToggleButton from "../IconToggleButton";

interface IRestProps {
    settings: ViewSettings;
    updateSettings: React.Dispatch<React.SetStateAction<ViewSettings>>;
}

const Rest = (props: IRestProps) => {
    return (
        <div className='topmenue'>
            <div className='largeSection'>
                <div className='spacedRow m-1'>
                    <div className="column">
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Skicka meddelande till aktiva resurser"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Ruttoptimera valdra uppdrag"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Avisering för valda uppdrag"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                    </div>
                    <div className="column">
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Visa översikt över fordon"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Förslag"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Föregående datum"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                    </div>
                    <div className="column">
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Nästa datum"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Tilldela resurs till valda uppdrag"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Expandera rader"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                    </div>
                    <div className="column">
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Kollapsa rader"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Visa bara ledia resurser i kön"
                            onClick={() => props.updateSettings({...props.settings, showOnlyEmptyUsers: !props.settings.showOnlyEmptyUsers})} 
                            isActive={props.settings.showOnlyEmptyUsers} 
                        />
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Send debug information"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                    </div>

                    <div className="column">
                        <IconToggleButton 
                            Icon={Settings} 
                            text="Visa samlastningar för resurser"
                            onClick={() => props.updateSettings({...props.settings, showCollectiveLoading: !props.settings.showCollectiveLoading})} 
                            isActive={props.settings.showCollectiveLoading} 
                        />
                    </div>
                </div>
            </div>
            <div className='smallSection'>
                <div className="column">
                    <IconToggleButton 
                        Icon={Settings} 
                        text="Automatfördela"
                        onClick={() => {}} 
                        isActive={false} 
                    />
                    <IconToggleButton 
                        Icon={Settings} 
                        text="Ett uppdrag"
                        onClick={() => {}} 
                        isActive={false} 
                    />
                </div>
            </div>
            <div className='smallSection'>
                <div className="column">
                    <IconToggleButton 
                        Icon={Settings} 
                        text="Skelelfteå lastbils"
                        onClick={() => {}} 
                        isActive={false} 
                    />
                    <IconToggleButton 
                        Icon={Settings} 
                        text="Ingen"
                        onClick={() => {}} 
                        isActive={false} 
                    />
                    <IconToggleButton 
                        Icon={Settings} 
                        text="Logga på"
                        onClick={() => {}} 
                        isActive={false} 
                    />
                </div>
            </div>
        </div>
    );
};

export default Rest;