import { calculateCostSummary } from "../../../helpers/CostSummary";
import CostDisplay from "../../costDisplay";

import "../../../styles/styles.css";
import UniversalTable from "../../UniversalTable";


export interface IPickupsDirectProps {
    createdNormal: Record<string, any>[] | undefined;
    createdDirect: Record<string, any>[] | undefined;
    distributed: Record<string, any>[] | undefined;
    loaded: Record<string, any>[] | undefined;
    selectedItem: string | undefined;
    filters: string[];
    onTap: (item: any) => void;
}

const PickupsTables = (props: IPickupsDirectProps) => {

    const createdNormalStats = calculateCostSummary(props.createdNormal || []);
    const createdDirectStats = calculateCostSummary(props.createdDirect || []);
    const distributedStats = calculateCostSummary(props.distributed || []);
    const loadedStats = calculateCostSummary(props.loaded || []);

    const CreatePickupColumns: string[] = [
        "stepIndex",
        "orderNr",
        "created",
        "custNr",
        "avsCompany",
        "avsAdrHouseNr",
        "avsPostOrt",
        "avsPostnr",
        "fromTimePickup",
        "toTimePickup",
        "recCompany",
        "recadressHouseNr",
        "recPostOrt",
        "recPostnr",
        "fromTimePickup",
        "toTimePickup",
        "vehicle",
        "kolliantal",
        "ordervikt",
        "ordervolume",
        "orderflakmeter",
        "orderpallplatser",
        "cargoType",
    ];

    const createdDirectPickupColumns: string[] = [
        "stepIndex",
        "orderNr",
        "created",
        "custNr",
        "avsCompany",
        "avsAdrHouseNr",
        "avsPostOrt",
        "avsPostnr",
        "fromTimePickup",
        "toTimePickup",
        "recCompany",
        "recadressHouseNr",
        "recPostOrt",
        "recPostnr",
        "fromTimePickup",
        "toTimePickup",
        "vehicle",
        "kolliantal",
        "ordervikt",
        "ordervolume",
        "orderflakmeter",
        "orderpallplatser",
        "cargoType",
    ];

    const pickupColumns: string[] = [
        "created",
        "fromTimePickup",
        "toTimePickup",
        "avsCompany",
        "avsAdrHouseNr",
        "avsPostOrt",
        "avsPostnr",
        "custNr",
        "custName",
        "recCompany",
        "recadressHouseNr",
        "recPostOrt",
        "recPostnr",
    ];

    return (
        <div className="orderTable" style={{ border: "solid 0.0625rem black", borderRadius: "3px", padding: 5 }}>
            <div className="row" style={{ alignItems: 'flex-start' }}>
                <div className="column">
                    <div className="expand">
                        <div className="spacedRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Skapade hämtningar</div>
                            <CostDisplay summary={createdNormalStats} />
                        </div>
                        <UniversalTable columns={CreatePickupColumns} items={props.createdNormal} selectedItem={props.selectedItem} onTap={props.onTap} />
                    </div>
                    <div className="expand">
                        <div className="spacedRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Skapade Direktkröningar</div>
                            <CostDisplay summary={createdDirectStats} />
                        </div>
                        <UniversalTable columns={createdDirectPickupColumns} items={props.createdDirect} selectedItem={props.selectedItem} onTap={props.onTap} />
                    </div>
                </div>
                <div className="column">
                    <div className="expand">
                        <div className="spacedRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Fördelade</div>
                            <CostDisplay summary={distributedStats} />
                        </div>
                        <UniversalTable columns={pickupColumns} items={props.distributed} selectedItem={props.selectedItem} onTap={props.onTap} />
                    </div>
                    <div className="expand">
                        <div className="spacedRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Lastade</div>
                            <CostDisplay summary={loadedStats}/>
                        </div>
                        <UniversalTable columns={pickupColumns} items={props.loaded} selectedItem={props.selectedItem} onTap={props.onTap} />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PickupsTables;