

export const timeWindow: number = 2 * 24 * 60 * 60 * 1000; // 48 hours in milliseconds


export const enum stepIndexes {
    created = 0,
    distributed = 1,
    loaded = 2,
    delivered = 3,
    denied = 4
}

export const enum columnTypes {
    fulldate,
    startDate,
    endDate,
    float,
    int,
    text
}

export const stepIndexLableMap: Record<number, string> = {
    0: "Skapad",
    1: "Fördelad",
    2: "Lastad",
    3: "Levererad",
    4: "Nekad"
}

export const columnLableMap: Record<string, string> = {
    "pod": "PDA",
    "stepIndex": "händelse",
    "created": "Datum",
    "arrivalDate": "Ankomstscan",
    "fromTimePickup": "HT",
    "toTimePickup": "HS",
    "recFromTimeDeliver": "LT",
    "recLastLevTime": "LS",
    "recCompany": "Mottagare",
    "recadressHouseNr": "M.Gata",
    "recPostOrt": "M.Ort",
    "recPostnr": "M.Pnr",
    "extraid5": "Ombud",
    "custNr": "Kundkod",
    "custName": "KundNamn",
    "avsCompany": "Avsändare",
    "avsAdrHouseNr": "A.Gata",
    "avsPostOrt": "A.Ort",
    "avsPostnr": "A.Pnr",
    "vehicle": "Resurs",
    "kolliantal": "kollin",
    "ordervikt": "Vikt",
    "ordervolume": "m3",
    "orderflakmeter": "flm",
    "orderpallplatser": "ppl",
    "orderNr": "OrderNr",
    "cargoType": "Godstyp",
    "message": "Medelande",
    "extra": "Tillval",
    "sorting": "Sortering",
    "shippingNr": "Fraktsedel",
    "freightBill": "Fraktsedel",
    "route": "Rutt",
    "missingCount": "SaknadeKollin",
    "wasDirect": "Direkt",
    "H": "H",
    "L1": "L1",
    "L2": "L2",
    "L3": "L3",
    "service": "Tjänst",
    "vehicleType": "Fordon",
    "problem": "Problem",
    "addon": "Tillval",
    "handOver": "handOver"
}

export const columnTypeMap: Record<string, columnTypes> = {
    "created": columnTypes.startDate,
    "arrivalDate" : columnTypes.startDate,

    "fromTimePickup": columnTypes.endDate,
    "toTimePickup": columnTypes.endDate,
    "recFromTimeDeliver": columnTypes.endDate,
    "recLastLevTime": columnTypes.endDate,

    "custNr": columnTypes.int,
    "orderNr": columnTypes.int,
    "shippingNr": columnTypes.int,
    "freightBill": columnTypes.int,
    "avsPostnr": columnTypes.int,
    "recPostnr": columnTypes.int,
    "ordervikt": columnTypes.float,
    "ordervolume": columnTypes.float,
    "orderflakmeter": columnTypes.float,
    "orderpallplatser": columnTypes.float,
    "kolliantal": columnTypes.int,
    "delivery": columnTypes.int,
    "pod": columnTypes.int,
    "stepIndex": columnTypes.int,
    "missingCount": columnTypes.int,

    "custName": columnTypes.text,
    "avsCompany": columnTypes.text,
    "avsAdrHouseNr": columnTypes.text,
    "avsPostOrt": columnTypes.text,
    "avsPhone": columnTypes.text,
    "recCompany": columnTypes.text,
    "recadressHouseNr": columnTypes.text,
    "recPostOrt": columnTypes.text,
    "recPhone": columnTypes.text,
    "extraid5": columnTypes.text,
    "vehicle": columnTypes.text,
    "cargoType": columnTypes.text,
    "message": columnTypes.text,
    "extra": columnTypes.text,
    "sorting": columnTypes.text,
    "route": columnTypes.text,
    "wasDirect": columnTypes.text,
    "H": columnTypes.text,
    "L1": columnTypes.text,
    "L2": columnTypes.text,
    "L3": columnTypes.text,
    "service": columnTypes.text,
    "vehicleType": columnTypes.text,
    "addon": columnTypes.text,
    "problem": columnTypes.text,
    "handOver": columnTypes.text
}


export const funcationTypeMap: Record<string, string> = {
    "dreq": "ankomst",
    "arrival": "ankomst",
    "loading": "uppHämtad",
    "directloading": "direkt uppHämtad",
    "changedparcel": "redigerad",
    "unload": "levererad",
    "damage": "skade rapoterad",
    "addtoorder": "adderad till order",
    "loadingterminal": "lastad terminal",
    "approvedpickup": "accepterad hämtning",
    "approveddelivery": "accepterad leverans",
    "newreturn": "returnerad",
    "neworder": "ny order",
    "newdeliveryorder": "ny leverans order",
    "newpickuporder": "ny hämtnings order",
    "newdelegateorder": "nytt ombud",
    "noapproval": "nekad order",
    "undoarrival": "borttagen ankomst",
    "approvedorder": "godkänd order",
    "denyorder": "nekad order",
    "currentlocation": "nuvarande position",
    "error": "fel rapporterat",
    "newmessage": "nytt meddelande",
};

export const timeColorMap: Record<string, string> = {
    "passed": "#ff0000"
}

export const stepIndexColorMap: Record<number, string> = {
    0: "white",
    1: "#ffff00",
    2: "#ff8000",
    3: "#00ff00",
    4: "#ff0000"
} 

export const customerTypeMap: Record<number, string> = {
    // 6004: "#00b050"
}